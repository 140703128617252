import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { AgeDisplay } from "../../helpers/AgeDisplay";

export const ReportDetailCard = ({ report }) => {
  return (
    <Card className="mb-4">
      <Card.Header className="bg-secondary text-white">Report Details</Card.Header>
      <Card.Body>
        <Table striped responsive>
          <tbody>
            <tr>
              <th>Creation Date</th>
              <td>
                {report.metadata.creationTimestamp}&nbsp; (
                <AgeDisplay date={report.metadata.creationTimestamp} />)
              </td>
            </tr>
            <tr>
              <th>Labels</th>
              <td>
                {Object.entries(report.metadata.labels).map(([key, value]) => (
                  <div key={key}>
                    <strong>{key}</strong>: {value}
                  </div>
                ))}
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
