import * as React from "react";
import { toast } from "react-toastify";
import { useAuthToken } from "./TokenProvider";
const { useState, useEffect } = React;

const AppContext = React.createContext(null);

const fetchData = (url, setter, errorHandler, token) => {
  const options = (token) ? {
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  }: {};

  fetch(url, options)
    .then(r => Promise.all([r.status, r.json()]))
    .then(([status, data]) => {
      if (Math.floor(status / 100) !== 2) {
        const message = `${status} - ${data.message}`;
        toast.error(message);
        errorHandler(true);
      }
      else {
        setter(data.items);
        errorHandler(false);
      }
    });
};

const createInterval = (url, setter, period, errorHandler, token) => {
  fetchData(url, setter, errorHandler, token);
  const interval = setInterval(() => {
    fetchData(url, setter, errorHandler, token);
  }, period);
  return () => clearInterval(interval);
}

export const AppContextProvider = ({config, children}) => {
  const token = useAuthToken();

  const [connected, setConnected] = useState(false);
  const [namespaces, setNamespaces] = useState(null);
  const [nodePools, setNodePools] = useState(null);
  const [activeNamespace, setActiveNamespace] = useState(null);
  const [hasFetchError, setHasFetchError] = useState(false);
  
  // Query namespaces
  useEffect(() => {
    return createInterval("/api/namespaces", setNamespaces, 120000, setHasFetchError, token);
  }, [token]);

  useEffect(() => {
    return createInterval("/api/node-pools", setNodePools, 120000, setHasFetchError, token);
  }, [token]);

  const appContext = {
    connected,
    setConnected,
    namespaces,
    nodePools,
    activeNamespace, 
    setActiveNamespace, 
    hasFetchError,
    config
  };

  return (
    <AppContext.Provider value={ appContext }>
      { children }
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return React.useContext(AppContext);
};
