import { useEffect, useState } from "react";
import { LoadingSpinner } from "../../LoadingSpinner";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

export const PodLogViewer = ({ pod, containerName }) => {
  const [logs, setLogs] = useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    setLogs(null);
    fetch(`/api/namespaces/${pod.metadata.namespace}/pods/${pod.metadata.name}/${containerName}/logs`)
      .then(r => r.text())
      .then(setLogs);
  }, [refreshCounter, pod, containerName]);

  return (
    <>
      <Alert variant="info">Note that logs are currently limited to the most recent 500 lines</Alert>
      
      { logs ? (
        <pre><code>{logs}</code></pre>
      ) : (
        <LoadingSpinner text="Loading logs..." />
      )}
      <p><Button onClick={() => setRefreshCounter(c => c + 1)}>Refresh</Button></p>
    </>
  );
};