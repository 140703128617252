import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export const NotFound = () => {
  return (
    <Container>
      <Row>
        <Col className="text-center">
          <FontAwesomeIcon icon={faQuestionCircle} size="6x" />
          <p>Page not found!</p>
        </Col>
      </Row>
    </Container>
  );
};