import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const ExternalLink = ({ children, ...rest }) => {
  return (
    <a {...rest} target="_blank" rel="noreferrer">
      { children }&nbsp;
      <FontAwesomeIcon icon={faExternalLinkAlt} />
    </a>
  );
};
