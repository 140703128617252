import { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import { SealedSecretForm } from "./SealedSecretForm";
import { getSealedSecret } from "@socialgouv/aes-gcm-rsa-oaep";
import yaml from "js-yaml";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

export const SealedSecretPage = ({ namespace, certificate }) => {
  const [showForm, setShowForm] = useState(true);
  const [manifestYaml, setManifestYaml] = useState(null);

  const onSubmit = useCallback(({ secretName, data, secretType }) => {
    setManifestYaml("");
    setShowForm(false);

    getSealedSecret({
      pemKey: certificate,
      namespace,
      scope: "strict",
      values: data,
      name: secretName,
    }).then(data => {
      data.spec.template.type = secretType;
      data.metadata.annotations = undefined;
      data.spec.template.metadata.annotations = undefined;
      data.spec.template.metadata.namespace = namespace;
      setManifestYaml(yaml.dump(data));
    });
  }, [certificate, namespace]);

  const onReset = useCallback(() => {
    setShowForm(true);
    setManifestYaml("");
  }, []);

  return (
    <>
      <div className="mb-5">
        <p>The form below can be used to create a SealedSecret for the <strong>{ namespace }</strong> namespace.</p>
        <p>Note that all encryption occurs within the browser and no secret content is sent anywhere. It is up to you to copy the YAML and place it into your manifest repository once encrypted.</p>
      </div>

      <div className={`mb-3 ${showForm ? "" : "d-none"}`}>
        <SealedSecretForm onSubmit={onSubmit} />
      </div>

      { manifestYaml && (
        <>
          <hr />
          <h3>
            Your YAML&nbsp;
            <CopyToClipboard text={manifestYaml} onCopy={() => toast.success("Manifest copied successfully!")}>
              <Button variant="secondary" className="ms-3">
                <FontAwesomeIcon icon={faCopy} />&nbsp;
                Copy
              </Button>
            </CopyToClipboard>
            &nbsp;
            <Button variant="secondary" className="ms-3" onClick={onReset}>Show Form</Button>
          </h3>
          <code className="fs-6"><pre>{ manifestYaml }</pre></code>
        </>
      )}
    </>
  );
};