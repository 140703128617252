import * as React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire } from '@fortawesome/free-solid-svg-icons';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    console.log("ERROR", error);
    return { error };
  }

  render() {
    if (this.state.error) {
      return <ErrorDisplay error={this.state.error} />;
    }

    return this.props.children;
  }
}

const ErrorDisplay = ({ error }) => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={{offset: 3, span: 6}}>
          <div className="text-center">
            <FontAwesomeIcon icon={faFire} size="6x" className="text-danger" />
            <h2 className="mt-3 mb-3">Uh oh! Something's happened!</h2>
            <p>It looks like something has caused an error in the application, causing it to crash.</p>
            <p>If this problem persists, please reach out to the platform team with the following information. We'd love to get it working again for you!</p>
          </div>
          <details>
            <summary className="text-center">Error Information</summary>
            <pre>
              { error.stack }
            </pre>
          </details>
        </Col>
      </Row>
    </Container>
  )
};