import { useCallback, useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useAppContext } from "../AppContextProvider";
import "./Header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { AuthDisplay } from "./AuthDisplay";
import { ConfigureCliButton } from "./ConfigureCliButton";
import { useUser } from "../UserProvider";
import { SealedSecretButton } from "./SealedSecretButton";

export const Header = () => {
  const { connected, namespaces, activeNamespace, config } = useAppContext();
  const { user } = useUser();
  const [newUrl, setNewUrl] = useState(null);
  const location = useLocation();
  const baseLocation = new URL(`https://${window.location.hostname}`);
  const clusterMap = [
    { "name": "prd-use1-100", "url": "https://dashboard.prd-use1-100.aws.platform.apsharedservices.com/" },
    { "name": "prd-usw2-100", "url": "https://dashboard.prd-usw2-100.aws.platform.apsharedservices.com/" },
    { "name": "apss-use1-100", "url": "https://dashboard.apss-use1-100.aws.platform.apsharedservices.com/" },
    { "name": "qa-use1-100", "url": "https://dashboard.qa-use1-100.aws.platform.apsharedservices.com/" },
    { "name": "dev-use1-100", "url": "https://dashboard.dev-use1-100.aws.platform.apsharedservices.com/" }
    /*
    { "name": "aws-prod", "url": "https://dashboard.platform.it.vt.edu/" },
    // { "name": "aws-pprd", "url": "https://dashboard.pprd.aws.itcp.cloud.vt.edu/ },
    { "name": "aws-dvlp", "url": "https://dashboard.dvlp.aws.itcp.cloud.vt.edu/" },
    { "name": "eksa-prod", "url": "https://dashboard.prod.op.itcp.cloud.vt.edu/" },
    { "name": "eksa-pprd", "url": "https://dashboard.pprd.op.itcp.cloud.vt.edu/" },
    { "name": "eksa-dvlp", "url": "https://dashboard.dvlp.op.itcp.cloud.vt.edu/" }
    */
  ]
  const thisCluster = config?.kubeConfig?.clusterName?.replace('-platform-cluster', '')

  useEffect(() => {
    if (location.pathname === newUrl) setNewUrl(null);
  }, [newUrl, location, setNewUrl]);

  const onNamespaceSelection = useCallback(
    (newNamespace) => {
      setNewUrl(`/namespaces/${newNamespace}`);
    },
    [setNewUrl]
  );

  const headerLinkStyle = {
    color: "#fff",
    fontSize: "1.25rem",
    marginRight: "1rem",
    paddingBottom: "0.3125rem",
    paddingTop: "0.3125rem",
    textDecoration: "none",
    whiteSpace: "nowrap",
  };

  if (newUrl && location.pathname !== newUrl) return <Navigate to={newUrl} />;

  return (
    <Navbar variant="dark" className="mb-5" id="top-nav-bar" expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to={baseLocation} style={headerLinkStyle}>
            <img alt="Associated Press" src="/assets/logo.svg" />
            Platform Dashboard
          </Link>
        </Navbar.Brand>
        <Navbar.Text className="pe-3">
          {activeNamespace && <ConnectionIndicator connected={connected} />}
        </Navbar.Text>
        <Navbar.Toggle aria-controls="main-menu-nav" />
        <Navbar.Collapse id="main-menu-nav" className="justify-content-end">
          <Nav>
            {activeNamespace && (
              <SealedSecretButton activeNamespace={activeNamespace} />
            )}
            {user && <ConfigureCliButton />}
            <NavDropdown
              title={activeNamespace ? activeNamespace : "Namespaces"}
              onSelect={onNamespaceSelection}
              align="end"
            >
              {namespaces.map((n) => (
                <NavDropdown.Item
                  key={n.metadata.name}
                  eventKey={n.metadata.name}
                  href="#"
                >
                  {n.metadata.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <NavDropdown
              title={thisCluster ? thisCluster: "Cluster"}
              align="end"
            >
              {clusterMap.map((n) => (
                <NavDropdown.Item
                  key={n.name}
                  href={n.url}
                >
                  {n.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            {user && <AuthDisplay />}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const ConnectionIndicator = ({ connected }) => {
  if (connected) return null;

  return (
    <>
      <FontAwesomeIcon icon={faSpinner} spin />
      &nbsp; Reconnecting...
    </>
  );
};
