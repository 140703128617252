import { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

export const EnvironmentVariableDisplay = ({ envVars }) => {
  const [numToShow, setNumToShow] = useState(10);
  
  const varsToShow = useMemo(() => {
    return [ ...envVars ].sort((a, b) => a.name < b.name ? -1 : 1)
      .slice(0, numToShow);
  }, [envVars, numToShow]);

  const displayAll = useCallback(() => {
    setNumToShow(envVars.length);
  }, [envVars]);

  return (
    <>
      <Table size="sm" className="mb-0 embedded-table">
        <tbody>
          { varsToShow.map((envVar) => (
            <tr key={envVar.name}>
              <th>{envVar.name}</th>
              <td>
                {envVar.value}
                { envVar.valueFrom && envVar.valueFrom.secretKeyRef && (
                  <em>Populated by the <strong>{envVar.valueFrom.secretKeyRef.key}</strong> key in the <strong>{envVar.valueFrom.secretKeyRef.name}</strong> secret</em>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      { numToShow < envVars.length && (
        <Button variant="link" onClick={displayAll} size="sm">
          Display all ({ envVars.length - numToShow } are hidden)
        </Button>
      ) }
    </>
  )
}