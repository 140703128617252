import * as React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { useK8sIssues } from "../issues/IssuesProvider";
import "./IssueOverviewCards.scss";
import { faExclamationCircle, faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { IssuesDisplay } from "../issues/IssuesDisplay";

export const IssueOverviewCards = () => {
  const issues = useK8sIssues();

  const [errors, setErrors] = React.useState([]);
  const [warnings, setWarnings] = React.useState([]);
  const [info, setInfo] = React.useState([]);

  const [modalDetails, setModalDetails] = React.useState({ show: false });

  React.useEffect(() => {
    let counts = { error : [], warning : [], info : [] };
    issues.forEach(i => counts[i.type].push(i));

    setErrors(counts.error);
    setWarnings(counts.warning);
    setInfo(counts.info);
  }, [issues]);

  const handleClose = React.useCallback(() => {
    setModalDetails({ show: false });
  }, []);

  const showErrors = React.useCallback(() => {
    setModalDetails({ 
      show: true,
      title: "Configuration Errors", 
      icon: faTimes,
      iconColor: "danger",
      issues: errors,
    });
  }, [errors]);

  const showWarnings = React.useCallback(() => {
    setModalDetails({ 
      show: true,
      title: "Configuration Warnings", 
      icon: faExclamationTriangle,
      iconColor: "warning",
      issues: warnings,
    });
  }, [warnings]);

  const showInfo = React.useCallback(() => {
    setModalDetails({ 
      show: true,
      title: "Configuration Info", 
      icon: faExclamationCircle,
      iconColor: "info",
      issues: info,
    });
  }, [info]);

  return (
    <>
      <Row>
        <Col>
          <Card className="bg-danger text-white overview-card" onClick={showErrors}>
            <div className="number">{ errors.length }</div>
            <div className="title">config issues</div>
          </Card>
        </Col>
        <Col>
          <Card className="bg-warning text-white overview-card" onClick={showWarnings}>
            <div className="number">{ warnings.length }</div>
            <div className="title">config warnings</div>
          </Card>
          </Col>
        <Col>
          <Card className="bg-info text-white overview-card" onClick={showInfo}>
            <div className="number">{ info.length }</div>
            <div className="title">config info</div>
          </Card>
        </Col>
      </Row>

      <Modal show={modalDetails.show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{ modalDetails.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { modalDetails.issues && modalDetails.issues.length === 0 && (
            <p><em>Hooray! You don't have any issues to display!</em></p>
          )}

          { modalDetails.issues && modalDetails.issues.length > 0 && (
            <IssuesDisplay issues={modalDetails.issues} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}