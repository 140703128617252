import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";

export const AdminLandingPage = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Admin Dashboards</h2>
          <p>Where would you like to go?</p>
          <ul>
            <li><Link to="node-pools">Node Pools</Link></li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}