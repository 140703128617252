import { useMemo } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { Pill } from "@thumbtack/thumbprint-react";
import { ExternalLink } from "../../helpers/ExternalLink";

export const ReportVulnerabilitiesCard = ({ vulnerabilities }) => {
    const sortedVulnerabilities = useMemo(() => {
        if (! vulnerabilities) return [];
        const sortMap = {
          'UNKNOWN': 0,
          'CRITICAL': 1,
          'HIGH': 2,
          'MEDIUM': 3,
          'LOW': 4
        }
        return [...vulnerabilities].sort((a, b) => (
          sortMap[a.severity] < sortMap[b.severity] ? -1 : 1
          ));
      }, [vulnerabilities]);
    
  return (
    <Card className="mb-3">
        <Card.Header className="bg-secondary text-white">Vulnerabilities</Card.Header>
        <Card.Body>
        <Table striped bordered hover responsive>
            <thead>
            <tr>
                <th>Vulnerability</th>
                <th>Severity</th>
                <th>Package</th>
                <th>Current version</th>
                <th>Fixed in version</th>
            </tr>
            </thead>
            <tbody>
            {sortedVulnerabilities.map(vulnerability => (
                <VulnerabilityRow vulnerability={vulnerability} />
            ))}
            </tbody>
        </Table>
        </Card.Body>
    </Card>
  );
};

const VulnerabilityRow = ({ vulnerability }) => {
  const getSeverity = (severity) => {
    let color="red";
    if (severity === "MEDIUM") {
      color="yellow";
    } else if (severity === "LOW") {
      color="blue"
    }
    return (
      <Pill  color={color}>{severity}</Pill>
    )

  }
  return (
    <tr>
      <td>
        <ExternalLink href={vulnerability.primaryLink}>{ vulnerability.vulnerabilityID }</ExternalLink><br/>
        {vulnerability.title}
      </td>
      <td>{ getSeverity(vulnerability.severity)}</td>
      <td>{ vulnerability.resource }</td>
      <td>{ vulnerability.installedVersion }</td>
      <td>{ vulnerability.fixedVersion }</td>
    </tr>
  );
};
