import * as React from "react";
import { useK8sDataWebSocket } from "../../utils/useK8sDataWebSocket";
const { useMemo, useState, useEffect, useCallback } = React;

const AdminDataContext = React.createContext(null);

export const AdminDataProvider = ({children}) => {
  const [webSocketUrl, setWebSocketUrl] = useState(null);
  const [pods, setPods] = useState(null);
  const [nodes, setNodes] = useState(null);

  const getSetter = useCallback((kind) => {
    switch (kind) {
      case "Pod":
        return setPods;
      case "Node":
        return setNodes;
      default:
        throw new Error("Unknown kind: " + kind);
    }
  }, []);

  useEffect(() => {
    setWebSocketUrl(`${window.location.origin.replace("http", "ws")}/api/cluster/events`);
  }, [setWebSocketUrl]);

  useK8sDataWebSocket(webSocketUrl, getSetter);

  const adminContext = useMemo(() => ({
    nodes, pods,
  }), [nodes, pods]);

  return (
    <AdminDataContext.Provider value={ adminContext }>
      { children }
    </AdminDataContext.Provider>
  );
};

export const useAdminData = () => {
  return React.useContext(AdminDataContext);
};

