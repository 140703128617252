import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppBootstrap from './AppBootstrap';
import reportWebVitals from './reportWebVitals';

fetch("/config")
  .then(r => r.json())
  .then(config => {
    if (!config.redirectUri)
      return config;

    return fetch("/api/verify-auth")
      .then(r => {
        if (r.status === 401)
          window.location = config.redirectUri;
        return config;
      });
  })
  .then(config => {
    ReactDOM.render(
      <React.StrictMode>
        <AppBootstrap config={config} />
      </React.StrictMode>,
      document.getElementById('root')
    );    
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
