import * as React from "react";
import { toast } from "react-toastify";
import { useAuthToken } from "../TokenProvider";
import { useK8sDataWebSocket } from "../../utils/useK8sDataWebSocket";
const { useMemo, useState, useEffect, useCallback } = React;


const NamespaceContext = React.createContext(null);
const PodMetricsContext = React.createContext(null);
const VulnerabilityReportContext = React.createContext(null);

const fetchData = (url, setter, errorHandler, token) => {
  const options = (token) ? {
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  }: {};

  fetch(url, options)
    .then(r => Promise.all([r.status, r.json()]))
    .then(([status, data]) => {
      if (Math.floor(status / 100) !== 2) {
        const message = `${status} - ${data.message}`;
        toast.error(message);
        errorHandler(true);
      }
      else {
        setter(data.items);
        errorHandler(false);
      }
    })
}

const createInterval = (url, setter, period, errorHandler, token) => {
  fetchData(url, setter, errorHandler, token);
  const interval = setInterval(() => {
    fetchData(url, setter, errorHandler, token);
  }, period);
  return () => clearInterval(interval);
}

export const NamespacedK8sDataProvider = ({namespace, children}) => {
  const token = useAuthToken();
  const [webSocketUrl, setWebSocketUrl] = useState(null);

  const [connected] = useState(false);
  const [pods, setPods] = useState(null);
  const [podMetrics, setPodMetrics] = useState(null);
  const [deployments, setDeployments] = React.useState(null);
  const [certificates, setCertificates] = useState(null);
  const [ingresses, setIngresses] = useState(null);
  const [services, setServices] = useState(null);
  const [endpoints, setEndpoints] = useState(null);
  const [kustomizations, setKustomizations] = useState(null);
  const [helmReleases, setHelmReleases] = useState(null);
  const [hasFetchError, setHasFetchError] = useState(false);
  const [vulnReports, setVulnReports] = useState(null);

  const getSetter = useCallback((kind) => {
    switch (kind) {
      case "Service":
        return setServices;
      case "Endpoints":
        return setEndpoints;
      case "Pod":
        return setPods;
      case "Deployment":
        return setDeployments;
      case "Certificate":
        return setCertificates;
      case "Ingress":
        return setIngresses;
      case "Kustomization":
        return setKustomizations;
      case "HelmRelease":
        return setHelmReleases;
      default:
        throw new Error("Unknown kind: " + kind);
    }
  }, []);

  useEffect(() => {
    if (!namespace) setWebSocketUrl(null);
    else setWebSocketUrl(`${window.location.origin.replace("http", "ws")}/api/namespaces/${namespace}/events`);
  }, [namespace, setWebSocketUrl]);

  useK8sDataWebSocket(webSocketUrl, getSetter);

  // Set pod metrics
  useEffect(() => {
    if (hasFetchError) return;
    
    setPodMetrics(null);
    if (namespace)
      return createInterval(`/api/namespaces/${namespace}/pod-metrics`, setPodMetrics, 10000, setHasFetchError, token);
  }, [namespace, hasFetchError, token]);

  // Set vulnReports
  useEffect(() => {
    if (hasFetchError) return;
    
    setVulnReports(null);
    if (namespace)
      return createInterval(`/api/namespaces/${namespace}/vulnerability-reports`, setVulnReports, 10000, setHasFetchError, token);
  }, [namespace, hasFetchError, token]);

  const getServiceAccounts = useCallback((setter) => {
    return fetchData(`/api/namespaces/${namespace}/service-accounts`, setter, () => {}, token);
  }, [namespace, token]);

  useEffect(() => {
    setPods(null);
    setPodMetrics(null);
    setVulnReports(null);
    setDeployments(null);
    setCertificates(null);
    setIngresses(null);
    setServices(null);
    setEndpoints(null);
    setKustomizations(null);
    setHelmReleases(null);
  }, [namespace]);

  const namespaceContext = useMemo(() => ({
    namespace,
    connected,
    pods, 
    deployments, 
    services, 
    endpoints,
    ingresses, 
    certificates, 
    kustomizations,
    hasFetchError,
    getServiceAccounts,
    helmReleases,
  }), [namespace, connected, pods, deployments, services, endpoints, ingresses, certificates, kustomizations, hasFetchError, getServiceAccounts, helmReleases]);

  const metrics = useMemo(() => ({
    metrics: podMetrics,
  }), [podMetrics]);

  const vulnerabilityReports = useMemo(() => ({
    vulnerabilityReports: vulnReports,
  }), [vulnReports]);

  return (
    <NamespaceContext.Provider value={ namespaceContext }>
      <PodMetricsContext.Provider value={metrics}>
        <VulnerabilityReportContext.Provider value={vulnerabilityReports}>
          { children }
        </VulnerabilityReportContext.Provider>
      </PodMetricsContext.Provider>
    </NamespaceContext.Provider>
  );
};

export const useNamespaceData = () => {
  return React.useContext(NamespaceContext);
};

export const usePodMetrics = () => {
  return React.useContext(PodMetricsContext);
}

export const useVulnerabilityReports = () => {
  return React.useContext(VulnerabilityReportContext);
}
