import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { LoadingSpinner } from "../../LoadingSpinner";
import k8s from "../../../utils/k8s";

export const IngressServicesCard = ({ ingress, services, endpoints, pods }) => {

  const matchingServices = useMemo(() => {
    if (!ingress || !services || !endpoints || !pods) return;
    
    const s = [];
    ingress.spec.rules.forEach(rule => {
      rule.http.paths.forEach(pathSpec => {
        const service = k8s.findServiceForIngressPath(pathSpec, services);
        const endpoint = endpoints.find(e => e.metadata.name === service.metadata.name);
        const servicePods = (service) ? k8s.getPodsForEndpoint(pods, endpoint) : [];
        const unavailablePods = (service) ? k8s.getPodsForEndpoint(pods, endpoint, false) : [];

        s.push({
          domain: rule.host,
          path: pathSpec.path,
          service,
          serviceName: (pathSpec.backend.service) ? pathSpec.backend.service.name : pathSpec.backend.serviceName,
          ingressPort: pathSpec.backend.service.port,
          pods: servicePods,
          unavailablePods,
          endpoint,
        });
      });
    });

    return s;
  }, [services, ingress, endpoints, pods]);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-secondary text-white">Services</Card.Header>
      <Card.Body>
        <p>In Kubernetes, Services are a network abstraction that allow pods to be discovered and receive network traffic. This is what connects an Ingress to your running pods.</p>

        { matchingServices ? (
          <>
            <ServicesTable ingressServices={matchingServices} />
            <UnavailablePodDisplay ingressServices={matchingServices} />
          </>
        ) : (
          <LoadingSpinner text="Loading data..." />
        )}
      </Card.Body>
    </Card>
  )
};

const ServicesTable = ({ ingressServices }) => {
  return (
    <Table striped bordered responsive>
      <thead>
        <tr>
          <th>Ingress Host and Path</th>
          <th>Will send Traffic to the Service</th>
          <th>On Port</th>
          <th>Which will forward to Port</th>
          <th>On pod</th>
        </tr>
      </thead>
      <tbody>
        { ingressServices.map((ingressService, index)=> (
          <IngressServiceRow ingressService={ingressService} key={index} />
        ))}
      </tbody>
    </Table>
  )
};

const IngressServiceRow = ({ ingressService }) => {
  const servicePort = useMemo(() => {
    if (!ingressService.service) return null;

    if (ingressService.ingressPort.number)
      return ingressService.service.spec.ports.find(p => p.port === ingressService.ingressPort.number);
    if (ingressService.ingressPort.name)
      return ingressService.service.spec.ports.find(p => p.name === ingressService.ingressPort.name);
    return null;
  }, [ingressService]);

  return (
    <tr>
      <td>{ ingressService.domain }{ ingressService.path }</td>
      <td>
        { ingressService.service ?
        ingressService.service.metadata.name : (
          <>
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />&nbsp;
            <em>No Service found! Expecting one named <strong>{ ingressService.serviceName }</strong></em>
          </>
        )}
      </td>
      <td>
        { ingressService.ingressPort.number && (
          `${ingressService.ingressPort.number}`
        )}

        { ingressService.ingressPort.name && (
          `Named ${ingressService.ingressPort.name}`
        )}
      </td>
      <td>
        { servicePort ? (
          <>
            { ingressService.ingressPort.name ? (
              `${ servicePort.name }`
            ) : (
              `${ servicePort.targetPort ? servicePort.targetPort : servicePort.port }`
            )}
          </>
        ) : ( <em>Unknown</em> )}
      </td>
      <td>
        { ingressService.pods.length === 0 && (
          <em>No available pods found</em>
        )}

        { ingressService.pods.length === 1 ? (
          <>{ ingressService.pods[0].metadata.name }</>
        ) : (
          <ul className="mb-0">
            { ingressService.pods.map(pod => (
              <li key={pod.metadata.name}>{ pod.metadata.name }</li>
            ))}
          </ul>
        )}

        { ingressService.unavailablePods.length > 0 && (
          <>
            Unavailable pods:
            <ul className="mb-0">
            { ingressService.unavailablePods.map(pod => (
              <li key={ pod.metadata.name }>{ pod.metadata.name }</li>
            ))}
            </ul>
          </>
        )}
      </td>
    </tr>
  );
}

const UnavailablePodDisplay = ({ ingressServices }) => {
  const hasUnavailablePods = useMemo(() => {
    return ingressServices.find(s => s.unavailablePods.length > 0) !== undefined;
  }, [ingressServices]);

  if (!hasUnavailablePods)
   return null;
  
  return (
    <>
      <h3>Troubleshooting Unavailable Pods</h3>
      <p>There are a variety of reasons pods may be unavailable to a service. The top reasons are as follows:</p>
      <ul>
        <li>A problem pulling the container image, preventing the pod from starting</li>
        <li>Failing readiness/liveness probes (if defined)</li>
        <li>Pods immediately failing after starting up</li>
      </ul>

      <p>If you expect any other pods to appear with the Service, ensure the Pod has all of the required labels that the Service is selecting.</p>
    </>
  )
};
