import { Route, Routes } from "react-router-dom";
import { AdminDataProvider } from "./AdminDataProvider";
import { AdminLandingPage } from "./AdminLandingPage";
import { NodePoolRoute } from "./NodePools/NodePoolRoute";

export const AdminRoute = () => {
  return (
    <AdminDataProvider>
      <Routes>
        <Route index element={<AdminLandingPage />} />
        <Route path="node-pools" element={<NodePoolRoute />} />
      </Routes>
    </AdminDataProvider>
  );
};