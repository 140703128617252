import NavDropdown from "react-bootstrap/NavDropdown";
import { useUser } from "../UserProvider";

export const AuthDisplay = () => {
  const { user, logout } = useUser();

  if (user === null)
    return null;

  return (
    <NavDropdown title={user.email}>
      <NavDropdown.Item onClick={logout}>Log out</NavDropdown.Item>
    </NavDropdown>
  )
}