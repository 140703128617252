import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

export const NodePools = ({ nodePools }) => {

  return (
    <Container>
      <Row>
        <Col>
          <ul>
            {nodePools.map((nodePool) => (
              <li key={nodePool}>
                <Link to={`/node-pools/${nodePool}`}>{nodePool}</Link>
              </li> 
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};
