import * as React from "react";
import { useNamespaceData } from "../NamespacedK8sDataProvider";
const { findIssues } = require("../../../issuesFinder");

export const IssuesProvider = ({ children }) => {
  const [issues, setIssues] = React.useState([]);
  const k8sData = useNamespaceData();

  React.useEffect(() => {
    setIssues(findIssues(k8sData));
  }, [k8sData]);

  return (
    <IssuesContext.Provider value={issues}>
      { children }
    </IssuesContext.Provider>
  )
};

const IssuesContext = React.createContext(null);

export const useK8sIssues = () => {
  return React.useContext(IssuesContext);
}