import { useEffect, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import "./Footer.scss";

const checkVersion = (knownVersion, setter) => {
  fetch("/version.json")
  .then(r => r.json())
  .then(({ version }) => {
    if (knownVersion && knownVersion !== version) {
      toast.info("A new version of the dashboard is available! Click here to get the latest version", {
        autoClose: false,
        closeOnClick: false,
        closeButton: null,
        toastId: "version-update",
        onClick: () => window.location.reload(),
      });
    }

    // Keep it if we had one, otherwise set it
    setter((v) => v ? v : version);
  });
}

export const Footer = () => {
  const [version, setVersion] = useState(null);
  const currentYear = useMemo(() => (new Date()).getFullYear(), []);

  useEffect(() => {
    if (version) return undefined;
    checkVersion(version, setVersion);
  }, [version]);

  useEffect(() => {
    if (!version) return undefined;
    const interval = setInterval(() => checkVersion(version, setVersion), 60000);
    return () => clearInterval(interval);
  }, [version]);

  return (
    <footer className="text-muted">
      <Container className="mt-3 mb-3">
        <Row>
          <Col className="text-center">
            Copyright &copy;2023-{ currentYear } Associated Press | Version: { version } | <a href="https://git.ap.org/gitops/platform-support/apps/dashboard/">Source Repo</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
