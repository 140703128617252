
const findService = (services, name) => {
  return services.find(s => s.metadata.name === name);
}

const findServiceForIngressPath = (path, services) => {
  if (path.backend.service)
    return findService(services, path.backend.service.name);
  return findService(services, path.backend.serviceName);
};

const findPodsForIngressPath = (ingressPath, endpoints, pods) => {
  const matchingEndpoint = findServiceForIngressPath(ingressPath, endpoints);
  if (!matchingEndpoint)
    return [];

  return getPodsForEndpoint(pods, matchingEndpoint);
};

const getPodsForEndpoint = (pods, endpoint, available = true) => {
  if (!endpoint) return [];

  const key  = (available) ? "addresses" : "notReadyAddresses";

  const targetRefs = [];

  if (!endpoint.subsets) return [];

  endpoint.subsets.forEach(subset => {
    if (!subset[key]) return;

    subset[key].forEach(address => {
      targetRefs.push(address.targetRef);
    });
  });

  return pods.filter(pod => 
    targetRefs.find(r => r.name === pod.metadata.name) !== undefined
  );
}

const findCertsForIngress = (certs, ingress) => {
  const matchingCerts = [];

  if (!ingress.spec.tls)
    return [];

  ingress.spec.tls.forEach((tlsRule) => {
    const matchingCert = certs.find((cert) => array_contains(cert.spec.dnsNames, tlsRule.hosts));
    if (matchingCert)
      matchingCerts.push(matchingCert);
  });

  return matchingCerts;
};

const array_contains = (arr, elements) => {
  for (let i = 0; i < elements.length; i++) {
    if (!arr.includes(elements[i]))
      return false;
  }
  return true;
}

module.exports = {
  findService,
  findPodsForIngressPath,
  findServiceForIngressPath,
  findCertsForIngress,
  getPodsForEndpoint,
};