import { faExclamationCircle, faExclamationTriangle, faExternalLinkAlt, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const typeMapping = {
  error: {
    icon: faTimes,
    iconColor: "danger",
  },
  warning: {
    icon: faExclamationTriangle,
    iconColor: "warning",
  },
  info: {
    icon: faExclamationCircle,
    iconColor: "info",
  }
};

export const IssuesDisplay = ({ issues }) => {
  return (
    <ul className="fa-ul mb-0">
    { issues && (
      issues.map((issue, index) => (
        <li key={index}>
          <FontAwesomeIcon icon={ typeMapping[issue.type].icon } listItem className={`text-${typeMapping[issue.type].iconColor}` } />
          { issue.description }&nbsp;
          { issue.helpLink && (
            <a href={ issue.helpLink } target="_blank" rel="noreferrer">
              Learn more&nbsp;
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          )}
        </li>
      ))
    )}
  </ul>

  )
}