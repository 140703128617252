import { useMemo } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom"
import { useParams } from "react-router"
import { useNamespaceData, usePodMetrics } from "../NamespacedK8sDataProvider";
import { NotFound } from "../../NotFound";
import { PodDetailCard } from "./PodDetailCard";
import { PodContainerCard } from "./PodContainerCard";
import { useK8sIssues } from "../issues/IssuesProvider";


export const PodInfoRoute = ({ config }) => {
  const issues = useK8sIssues();
  const { podIdentifier } = useParams();
  const { namespace, pods } = useNamespaceData();
  const { metrics } = usePodMetrics();

  const pod = useMemo(() => {
    if (!pods) return null;
    return pods.find(p => p.metadata.name === podIdentifier);
  }, [pods, podIdentifier]);

  const podIssues = useMemo(() => {
    if (!pod || !issues) return null;
    return issues.filter(issue => issue.references.find(r => r.metadata.name === pod.metadata.name) !== undefined);
  }, [pod, issues]);

  const podMetrics = useMemo(() => {
    if (!pod || !metrics) return null;
    return metrics.find(p => p.metadata.name === podIdentifier);
  }, [metrics, podIdentifier, pod]);

  if (!pod)
    return <NotFound />;
  
  return (
    <Container>
      <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/` }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `../` }}>{namespace}</Breadcrumb.Item>
            <Breadcrumb.Item>Pods</Breadcrumb.Item>
            <Breadcrumb.Item active>{ podIdentifier }</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{ pod.metadata.name }</h2>
          <PodDetailCard config={config} pod={pod} issues={podIssues} />
          { pod.spec.containers.map((container) => (
            <PodContainerCard key={container.name} pod={pod} container={container} podMetrics={podMetrics} />
          ))}
        </Col>
      </Row>
    </Container>
  )
}