import Accordion from 'react-bootstrap/Accordion';
import { useMemo } from "react";
import { ExternalLink } from "../../helpers/ExternalLink";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../../LoadingSpinner";
import { PodStatus } from "../../helpers/PodStatus";
import { AgeDisplay } from "../../helpers/AgeDisplay";
import { InputFilter, TableFilter } from "../Filters";
import { useTableContext, TableContextProvider } from "../../context/TableContext";
const k8s = require("../../../utils/k8s");

export const DomainsCard = ({ eventKey, ingresses, services, pods, endpoints }) => {

  return (
    <TableContextProvider data={ingresses} total={ingresses.length} >
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>Your Domains ({ingresses.length})</Accordion.Header>
        <Accordion.Body>
          <DomainFilter />
          {(ingresses && services && endpoints && pods) ?
            <DomainsTable pods={pods} endpoints={endpoints} />
            : <LoadingSpinner text="Loading data..." />
          }
        </Accordion.Body>
      </Accordion.Item>
    </TableContextProvider>
  )
};

const DomainsTable = ({ endpoints, pods }) => {
  const { data, filteredData, filteredTotal, total } = useTableContext();

  const domains = useMemo(() => {
    const domainPaths = [];

    filteredData.forEach((ingress) => {
      ingress.spec.rules.forEach((rule) => {
        rule.http.paths.forEach((pathRule) => {
          domainPaths.push({
            domain: rule.host,
            path: pathRule.path,
            pathType: pathRule.pathType,
            pods: k8s.findPodsForIngressPath(pathRule, endpoints, pods),
            ingress,
          });
        });
      });
    });

    return domainPaths.sort((a, b) => `${a.domain}${a.path}` < `${b.domain}${b.path}` ? -1 : 1);
  }, [filteredData, endpoints, pods]);

  if (data.length > 0 && filteredTotal < 1) {
    return (
      <p>No ingresses match the filter provided</p>
    );
  }

  if (domains.length === 0)
    return (
      <p>You have no domains defined yet!</p>
    );

  return (
      <Table bordered className="body-striped" responsive>
      <thead>
        <tr>
          <th>Domain and Path</th>
          <th>Served by</th>
          <th>Status</th>
          <th>Age</th>
        </tr>
      </thead>
      { domains.map((domain) => (
        <tbody key={`${domain.domain}/${domain.path}`}>
          <tr>
            <td rowSpan={Math.max(2, domain.pods.length + 1)}>
              <Link to={`ingresses/${domain.ingress.metadata.name}`}>
                { domain.domain }{ domain.path }
              </Link>
              &nbsp;
              { domain.pods.length > 0 && (
                <ExternalLink href={`https://${domain.domain}${domain.path}`} className="ms-3">
                  Open URL
                </ExternalLink>
              )}
            </td>
          </tr>
          
          { domain.pods.length === 0 && (
            <tr>
              <td colSpan={3}><em>No pods are currently connected to this ingress</em></td>
            </tr>
          )}

          { domain.pods.map((pod) => (
            <tr key={pod.metadata.name}>
              <td>
                <Link to={`pods/${pod.metadata.name}`}>
                  { pod.metadata.name }
                </Link>
              </td>
              <td><PodStatus pod={pod} /></td>
              <td><AgeDisplay date={pod.metadata.creationTimestamp} /></td>
            </tr>
          ))}
        </tbody>
      ))}
      <tfoot>
        <tr><td>{filteredTotal} of {total}</td></tr>
      </tfoot>
    </Table>
  );

};

const DomainFilter = () => {
  const domainQuery = (ingress, inputValue) =>
    ingress.spec.rules.find((rule) =>
      rule.host.includes(inputValue));

  return (<TableFilter>
    <InputFilter name="domain" placeholder="Filter by domain name" query={domainQuery} />
  </TableFilter>);
}