import { useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthToken } from "../TokenProvider";
import { NodePoolDashboard } from "./NodePoolDashboard";

const NodePoolRoute = ({config}) => {
  const token = useAuthToken();
  const { nodePool } = useParams();
  const [nodePools, setNodePools] = useState(null);
  const [hasFetchError, setHasFetchError] = useState(false);

  const fetchData = (url, setter, errorHandler, token) => {
    const options = token
      ? {
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
        }
      : {};

    fetch(url, options)
      .then((r) => Promise.all([r.status, r.json()]))
      .then(([status, data]) => {
        if (Math.floor(status / 100) !== 2) {
          const message = `${status} - ${data.message}`;
          toast.error(message);
          errorHandler(true);
        } else {
          setter(data.items);
          errorHandler(false);
        }
      });
  };

  useEffect(() => {
    const createInterval = (url, setter, period, errorHandler, token) => {
      fetchData(url, setter, errorHandler, token);
      const interval = setInterval(() => {
        fetchData(url, setter, errorHandler, token);
      }, period);
      return () => clearInterval(interval);
    };
    if (hasFetchError) return;

    setNodePools(null);
    if (nodePool)
      return createInterval(
        `/api/node-pools`,
        setNodePools,
        30000,
        setHasFetchError,
        token
      );
  }, [nodePool, hasFetchError, token]);

  return (
    <Routes>
      <Route
        index
        exact
        element={<NodePoolDashboard config={config} pool={nodePool} nodePools={nodePools} />}
      />
    </Routes>
  );
};

export default NodePoolRoute;
