import { useMemo } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom"
import { useParams } from "react-router"
import { useNamespaceData } from "../NamespacedK8sDataProvider";
import { NotFound } from "../../NotFound";
import { CertificateIngressCard } from "./CertificateIngressCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { IngressServicesCard } from "./IngressServicesCard";

export const IngressRoute = () => {
  const { ingressName } = useParams();
  const { namespace, ingresses, certificates, services, endpoints, pods } = useNamespaceData();

  const ingress = useMemo(() => {
    if (!ingresses) return null;
    return ingresses.find(i => i.metadata.name === ingressName);
  }, [ingresses, ingressName]);

  const paths = useMemo(() => {
    if (!ingress) return;
    
    const p = [];
    ingress.spec.rules.forEach((rule) => {
      rule.http.paths.forEach((path) => {
        p.push(`${rule.host}${path.path}`);
      });
    });
    return p;
  }, [ingress]);

  if (!ingress)
    return <NotFound />;
  
  return (
    <Container>
      <Row className="mb-5">
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/` }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../" }}>{namespace}</Breadcrumb.Item>
            <Breadcrumb.Item>Ingresses</Breadcrumb.Item>
            <Breadcrumb.Item active>{ ingressName }</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col className="text-end">
          { paths.length === 1 && (
            <Button href={`https://${paths[0]}`} target="_blank" rel="noreferrer" variant="secondary" size="sm">
              Open { paths[0] }
              &nbsp;&nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} />
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{ ingress.metadata.name }</h2>
          <p>Getting HTTP traffic through an Ingress to a running Pod has several steps of configuration. This page helps outline all of those pieces and helps highlight any potential misconfigurations.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <CertificateIngressCard ingress={ingress} certificates={certificates} />
          <IngressServicesCard ingress={ingress} services={services} endpoints={endpoints} pods={pods} />
        </Col>
      </Row>
    </Container>
  );
}
