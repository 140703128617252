import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

export const Namespaces = ({ namespaces, setActiveNamespace }) => {

  return (
    <Container>
      <Row>
        <Col>
          <ul>
            {namespaces.map((namespace) => (
              <li key={namespace.metadata.name}>
                <Link to={`/namespaces/${namespace.metadata.name}`}>{namespace.metadata.name}</Link>
              </li> 
            ))}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};
