import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Namespaces } from "./Namespaces";
import { NodePools } from "./NodePools";

export const WelcomePage = ({ namespaces, setActiveNamespace,  nodePools }) => {

  return (
    <Container>
      <Row>
        <Col>
          <p>Choose a tenant namespace below!</p>
          <Namespaces namespaces={namespaces} setActiveNamespace={setActiveNamespace} />
        </Col>
        { ( (Object.keys(nodePools).length > 0 ) ?
        <Col>
          <p>OR choose a node pool below!</p>
          <NodePools nodePools={Object.keys(nodePools).sort()} />
        </Col>
        : "" ) }
      </Row>
    </Container>
  );
};
