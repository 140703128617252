import { useMemo } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useParams } from "react-router";
import { useNamespaceData } from "../NamespacedK8sDataProvider";
import { useVulnerabilityReports } from "../NamespacedK8sDataProvider";
import { ReportDetailCard } from "./ReportDetailCard";
import { ReportVulnerabilitiesCard } from "./ReportVulnerabilitiesCard";

export const VulnerabilityReportsRoute = ({ config }) => {
  const { reportName } = useParams();
  const { namespace } = useNamespaceData();
  const { vulnerabilityReports } = useVulnerabilityReports();

  const report = useMemo(() => {
    if (!vulnerabilityReports) return null;
    return vulnerabilityReports.find((p) => p.metadata.name === reportName);
  }, [vulnerabilityReports, reportName]);

  if (! report) {
    return (<>Loading report...</>);
  }
  return (
    <Container>
      <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/` }}>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `../` }}>
              {namespace}
            </Breadcrumb.Item>
            <Breadcrumb.Item>Pods</Breadcrumb.Item>
            <Breadcrumb.Item active>{reportName}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>{ reportName }</h2>
          <ReportDetailCard report={ report } />
          <ReportVulnerabilitiesCard vulnerabilities={report.report.vulnerabilities} />
        </Col>
      </Row>
    </Container>
  );
};
