import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/**
 * 
 * @param {object} props 
 * @param {nodes} props.children - React Bootstrap Form Controls to add to the table filter
 * @param {string} props.controlId - id to apply to the Form.Group component
 * @param {string} props.className - React Bootstrap class to apply to the Form.Group component
 * @param {number} props.displayAt - Minimum number of records in the table before the filter is displayed
 * @returns 
 */
const TableFilter = (props) => {
  const {
    children,
    className = "mb-2",
  } = props;

  return (
    <Row>
      <Col xs={12} lg={4} className={className}>
        {children}
      </Col>
    </Row>)
}

export default TableFilter;