import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

export const RegistryForm = ({ onChange }) => {
  const [registry, setRegistry] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!registry || !username || !password)
      return onChange(null);

    const auth = {
      auths: {
        [registry]: {
          username, password,
          auth: btoa(`${username}:${password}`)
        }
      }
    };

    const data = {
      ".dockerconfigjson": JSON.stringify(auth)
    };

    onChange(data);
  }, [registry, username, password, onChange]);

  return (
    <Row>
      <Form.Group as={Row} className="mb-3" controlId="registryUrl">
        <Form.Label column sm={2}>Registry URL</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" onChange={(e) => setRegistry(e.target.value)} value={registry} />
          <Form.Text className="text-muted">
            e.g., code.vt.edu:5005, harbor.platform.it.vt.edu
          </Form.Text>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="registryUsername">
        <Form.Label column sm={2}>Username</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" onChange={(e) => setUsername(e.target.value)} value={username} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="registryPassword">
        <Form.Label column sm={2}>Password</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" onChange={(e) => setPassword(e.target.value)} value={password} />
        </Col>
      </Form.Group>
    </Row>
  );

};