import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

export const KeyValueForm = ({ onChange }) => {
  const [kvPairs, setKvPairs] = useState([{ key : "", value: "" }]);

  const addItem = useCallback(() => {
    setKvPairs(kv => [...kv, { key : "", value: "" }]);
  }, []);

  const onItemChange = useCallback((index, {key, value}) => {
    setKvPairs((kvPairs) => [...kvPairs.slice(0, index), {key, value}, ...kvPairs.slice(index + 1)]);
  }, []);

  const removeElement = useCallback((index) => {
    setKvPairs((kvPairs) => [...kvPairs.slice(0, index), ...kvPairs.slice(index + 1)]);
  }, []);

  useEffect(() => {
    const map = kvPairs
      .filter(item => item.key && item.value)
      .reduce((acc, curr) => ({ ...acc, [curr.key] : curr.value }), {});
    onChange(map);
  }, [kvPairs, onChange]);

  return (
    <Row>
      <Col sm={2}><Form.Label>Secret Items</Form.Label></Col>
      <Col>
        { kvPairs.map((v, index) => (
          <KeyValueEntry key={index} 
            entry={v} 
            index={index} 
            onChange={(data) => onItemChange(index, data)} 
            onDelete={() => removeElement(index)} 
          />
        )) }
        <Button onClick={addItem}>Add Item</Button>
      </Col>
    </Row>
  );
};

export const KeyValueEntry = ({ entry, index, onChange, onDelete }) => {

  const onKeyUpdate = (e) => {
    onChange({ key: e.target.value, value: entry.value });
  };

  const onValueUpdate = (e) => {
    onChange({ key: entry.key, value: e.target.value });
  };

  return (
    <Row className="mb-3">
      <Col sm={4}>
        <Form.Control type="text" value={entry.key} onChange={onKeyUpdate} placeholder="Key" />
      </Col>
      <Col sm={5}>
        <Form.Control as="textarea" rows="1" value={entry.value} onChange={onValueUpdate} placeholder="Value" />
      </Col>
      <Col sm={2}>
        { index > 0 && (
          <Button variant="danger" onClick={onDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </Col>
    </Row>
  );
};