import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import NodeInfoCard from "./NodeInfoCard";

const NodePoolCard = ({ nodePool }) => {
  return (
    <Card className="mb-4">
      <Card.Header className="bg-secondary text-white">{ nodePool.id } ({ nodePool.nodes.length })</Card.Header>
      <Card.Body>
        <p>
          <strong>Cost Code:</strong> { nodePool.costCode ? nodePool.costCode : <em>Unknown</em> }
        </p>

        <Container fluid>
          <Row>
            { nodePool.nodes.map(node => (
              <Col key={node.id} sm={3} className="mb-4">
                <NodeInfoCard node={node} />
              </Col>
            ))}
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default NodePoolCard;