import { useMemo } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { LoadingSpinner } from "../../LoadingSpinner";
import { CpuUsageDisplay } from "./CpuUsageDisplay";
import { MemoryUsageDisplay } from "./MemoryUsageDisplay";
import { EnvironmentVariableDisplay } from "./EnvironmentVariableDisplay";
import { PodLogLauncher } from "./PodLogLauncher";

export const PodContainerCard = ({ pod, container, podMetrics }) => {
  const containerMetrics = useMemo(() => {
    if (!podMetrics) return null;
    const containerUsage = podMetrics.containers.find(c => c.name === container.name);
    return (containerUsage) ? containerUsage.usage : null;
  }, [podMetrics, container.name]);

  const sortedVolumeMounts = useMemo(() => {
    if (!container.volumeMounts) return [];
    return [...container.volumeMounts].sort((a, b) => a.name < b.name ? -1 : 1);
  }, [container.volumeMounts]);

  return (
    <Card className="mb-4">
      <Card.Header className="bg-secondary text-white d-flex justify-content-between align-items-center">
        Container Details - { container.name }
        <PodLogLauncher containerName={container.name} pod={pod} />
      </Card.Header>
      <Card.Body>
        <Table striped responsive>
          <tbody>
            <tr>
              <th>Image</th>
              <td>{ container.image }</td>
            </tr>
            <tr>
              <th>Environment Variables</th>
              <td>
                { container.env ? (
                  <EnvironmentVariableDisplay envVars={container.env} />
                ) : (<em>None configured</em>)}
              </td>
            </tr>
            <tr>
              <th>Ports</th>
              <td>
                { (container.ports && container.ports.length > 0) ? (
                  <>
                    { container.ports.map((port) => (
                      <div key={port.containerPort}>
                        {port.containerPort}/{port.protocol}
                      </div>
                    ))}
                  </>
                ) : (<em>No ports configured</em>)}
              </td>
            </tr>
            <tr>
              <th>Volumes</th>
              <td>
                { sortedVolumeMounts.map((volumeMount) => (
                  <div key={volumeMount.mountPath}>
                    <strong>{ volumeMount.name }</strong> mounted at <strong>{volumeMount.mountPath}</strong>
                  </div>
                ))}
              </td>
            </tr>
            <tr>
              <th>Readiness Probe</th>
              <td>
                { container.readinessProbe ? <ProbeInfo probe={container.readinessProbe} /> : <em>None configured</em> }
              </td>
            </tr>
            <tr>
              <th>Liveness Probe</th>
              <td>
                { container.livenessProbe ? <ProbeInfo probe={container.livenessProbe} /> : <em>None configured</em> }
              </td>
            </tr>
            <tr>
              <th>CPU Utilization</th>
              <td>
                {
                  containerMetrics ? (
                    <>
                      {
                        (container.resources ? (
                          <CpuUsageDisplay usage={containerMetrics.cpu}
                          limit={ (container.resources.limits && container.resources.limits.cpu) ? container.resources.limits.cpu : null }
                          request={ (container.resources.requests && container.resources.requests.cpu) ? container.resources.requests.cpu : null } />
                        ) : (
                          <CpuUsageDisplay usage={containerMetrics.cpu} />
                        ))
                      }
                    </>
                  ) : (
                    <LoadingSpinner text="Loading metrics..." />
                  )
                }
              </td>
            </tr>
            <tr>
              <th>Memory Utilization</th>
              <td>
                {
                  containerMetrics ? (
                    <>
                      {
                        (container.resources ? (
                          <MemoryUsageDisplay usage={containerMetrics.memory}
                          limit={ (container.resources.limits && container.resources.limits.memory) ? container.resources.limits.memory : null }
                          request={ (container.resources.requests && container.resources.requests.memory) ? container.resources.requests.memory : null } />
                        ) : (
                          <MemoryUsageDisplay usage={containerMetrics.memory} />
                        ))
                      }
                    </>
                  ) : (
                    <LoadingSpinner text="Loading metrics..." />
                  )
                }
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const ProbeInfo = ({ probe }) => {

  const execution = useMemo(() => {
    if (probe.httpGet)
      return `HTTP GET requests to ${probe.httpGet.path } on port ${ probe.httpGet.port }`;
    if (probe.exec)
      return `Executes ${probe.exec.command.join (" ")}`;
    if (probe.tcpSocket)
      return `TCP check on port ${probe.tcpSocket.port}`;
  }, [probe]);

  return (
    <Table className="embedded" size="sm">
      <tbody>
        <tr>
          <th>Execution</th>
          <td>{ execution }</td>
        </tr>
        <tr>
          <th>Initial Delay</th>
          <td>{ probe.initialDelaySeconds ? probe.initialDelaySeconds : "0" }</td>
        </tr>
        <tr>
          <th>Frequency</th>
          <td>{ probe.periodSeconds }</td>
        </tr>
        <tr>
          <th>Timeout</th>
          <td>{ probe.timeoutSeconds }</td>
        </tr>
        <tr>
          <th>Success Threshold</th>
          <td>{ probe.successThreshold }</td>
        </tr>
        <tr>
          <th>Failure Threshold</th>
          <td>{ probe.failureThreshold }</td>
        </tr>
      </tbody>
    </Table>
  )
}