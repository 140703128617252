import { useCallback, useEffect } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { useAppContext } from "../components/AppContextProvider";
import { useAuthToken } from "../components/TokenProvider";
import pako from "pako";

const base64ToArrayBuffer = (base64) => {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

/**
 * Use a WebSocket that provides K8s events.
 * @param {string} url The URL to connect the WebSocket to
 * @param {() => setter)} getSetter A function that receives the kind of object received. 
 * Expected to return a useState setter so smart merging can occur.
 */
export const useK8sDataWebSocket = (url, getSetter) => {
  const { setConnected } = useAppContext();
  const token = useAuthToken();

  /**
   * When messages are received, decompress the message, find the appropriate setter,
   * and then update the state in a smart way (to prevent unnecessary state updates and re-renders).
   */
  const onMessage = useCallback((message) => {
    const data = JSON.parse(pako.inflate(base64ToArrayBuffer(message.data), {to: "string"}));
    const {event, type} = data;
    if (type !== "event")
      return;

    if (event.type === "SET") {
      Object.keys(event.data)
        .forEach((objectKind) => {
          const setter = getSetter(objectKind);
          setter(event.data[objectKind]);
        });
      return;
    }

    const setter = getSetter(event.object.kind);
    setter((currentData = []) => {
      const existingData = (currentData) ? currentData : [];

      // Handle add/change events by looking for the element. If not found, add it. 
      // Otherwise, see if a change actually occurred by comparing the object's resourceVersion
      if (event.type === "ADDED" || event.type === "MODIFIED") {
        const index = existingData.findIndex(d => d.metadata.name === event.object.metadata.name);
        if (index === -1) return [...existingData, event.object];

        if (existingData[index].metadata.resourceVersion === event.object.metadata.resourceVersion)
          return existingData;

          return [...existingData.slice(0, index), event.object, ...existingData.slice(index + 1)];
      }

      // Handle delete events by finding the element and removing it
      if (event.type === "DELETED") {
        const index = existingData.findIndex(d => d.metadata.name === event.object.metadata.name);
        if (index > -1)
          return [...existingData.slice(0, index), ...existingData.slice(index + 1)];
        return existingData;
      }
    });      
  }, [getSetter]);

  useEffect(() => {
    if (!url) return;

    const rws = new ReconnectingWebSocket(url);

    let pingInterval = null; 
    rws.addEventListener("open", () => {
      setConnected(true);
      rws.send(JSON.stringify({ token }));
      console.log("WebSocket opened");
  
      // Used to prevent TCP disconnects for inactivity
      pingInterval = setInterval(() => rws.send(JSON.stringify({ type: "heartbeat" })), 5000);
    });
  
    rws.addEventListener("message", onMessage);
  
    rws.addEventListener("close", () => {
      setConnected(false);
      console.log("WebSocket closed");
      
      if (pingInterval) {
        clearInterval(pingInterval);
        pingInterval = null;
      }
    });
  
    return () => rws.close();
  }, [url, getSetter, setConnected, token, onMessage]);
};