import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { KeyValueForm } from "./KeyValueForm";
import { RegistryForm } from "./RegistryForm";

export const SealedSecretForm = ({ onSubmit }) => {
  const [secretName, setSecretName] = useState("");
  const [secretType, setSecretType] = useState("Opaque");
  const [data, setData] = useState(null);
  const [valid, setValid] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (data && secretName)
      onSubmit({ secretName, data, secretType });
  };

  const setType = useCallback((evt) => {
    setSecretType(evt.target.value);
    setData(null);
  }, []);

  useEffect(() => {
    setValid(secretName && data && Object.keys(data).length > 0);
  }, [secretName, data]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Row} className="mb-3" controlId="secretName">
        <Form.Label column sm={2}>Secret Name</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" placeholder="Secret Name" value={secretName} onChange={(evt) => setSecretName(evt.target.value)} />
          <Form.Text className="text-muted">
            The name of the secret to create
          </Form.Text>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="secretType">
        <Form.Label column sm={2}>Secret Type</Form.Label>
        <Col sm={8}>
          <Form.Control as="select" onChange={setType}>
            <option value="Opaque" selected={secretType === "key/value"}>Key/Value</option>
            <option value="kubernetes.io/dockerconfigjson" selected={secretType === "registry"}>Image Registry</option>
          </Form.Control>
        </Col>
      </Form.Group>

      { secretType === "Opaque" && (
        <KeyValueForm onChange={setData} />
      )}

      { secretType === "kubernetes.io/dockerconfigjson" && (
        <RegistryForm onChange={setData} />
      )}

      <Button variant="primary" type="submit" disabled={!valid}>Generate Manifest</Button>
    </Form>
  );
};