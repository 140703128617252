import {
  faCheckCircle,
  faExternalLinkAlt,
  faQuestionCircle,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LoadingSpinner } from "../../LoadingSpinner";

export const KustomizationCard = ({ config, namespace, kustomizations }) => {
  const kustomization = kustomizations ? kustomizations[0] : null;

  const namespaceUrl = () => {
    let clusterPath;
    if (config.kubeConfig.clusterName === "prod-2-use1") {
      clusterPath = "aws-prod";
    } else if (
      config.kubeConfig.clusterName === "onprem-prod-platform-cluster"
    ) {
      clusterPath = "op-prod";
    } else if (config.kubeConfig.clusterName === "shared-1-use1") {
      clusterPath = "aws-shared";
    } else if (
      config.kubeConfig.clusterName === "onprem-pprd-platform-cluster"
    ) {
      clusterPath = "op-pprd";
    } else if (config.kubeConfig.clusterName === "aws-dvlp-platform-cluster") {
      clusterPath = "aws-dvlp";
    } else if (
      config.kubeConfig.clusterName === "onprem-dvlp-platform-cluster"
    ) {
      clusterPath = "op-dvlp";
    } else {
      // default is aws-prod
      clusterPath = "aws-prod";
    }
    if (namespace === "it-common-platform-dashboard") {
        return `https://git.ap.org/gitops/tenants/${namespace}`;
    } else {
        return `https://git.ap.org/gitops/tenants/${clusterPath}/${namespace}`;
    }
  };

  const status = React.useMemo(() => {
    if (!kustomization) {
      return {
        bg: "warning",
        text: "dark",
        icon: faQuestionCircle,
        heading: "No Kustomization found",
        subheading: "This is a platform issue, not yours!",
      };
    }

    const reason = kustomization.status.conditions[0].reason;
    switch (reason) {
      case "ReconciliationSucceeded":
        return {
          bg: "success",
          text: "light",
          icon: faCheckCircle,
          heading: "Latest changes have been applied!",
          subheading: kustomization.status.lastAttemptedRevision,
        };
      case "ReconciliationFailed":
      case "BuildFailed":
        return {
          bg: "danger",
          text: "light",
          icon: faTimesCircle,
          heading: "Latest changes failed to be applied",
          subheading: `Attempted: ${kustomization.status.lastAttemptedRevision}`,
        };
      case "Progressing":
      case "DependencyNotReady":
        return {
          bg: "info",
          text: "light",
          icon: faSpinner,
          heading: "Applying updates...",
          spin: true,
        };
      case "ProgressingWithRetry":
        return {
          bg: "info",
          text: "light",
          icon: faSpinner,
          heading: "Retrying updates...",
          subheading: kustomization.status.conditions[1].message,
          spin: true,
        };
      default:
        return { bg: "primary", text: "light", icon: faQuestionCircle };
    }
  }, [kustomization]);

  return (
    <Card bg={status.bg} text={status.text}>
      <Card.Body>
        {kustomizations ? (
          <>
            <Row>
              <Col md="2" className="text-center align-self-center">
                <FontAwesomeIcon
                  icon={status.icon}
                  size="3x"
                  spin={status.spin}
                />
              </Col>
              <Col md="10">
                <h5>{status.heading}</h5>
                {status.subheading && (
                  <div className="text-truncate" title={status.subheading}>
                    <small>{status.subheading}</small>
                  </div>
                )}
                <a
                  href={namespaceUrl()}
                  className="text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  Open manifest repo&nbsp;
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              </Col>
            </Row>
          </>
        ) : (
          <div className="text-center">
            <LoadingSpinner text="" />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
