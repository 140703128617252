import { useMemo } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { LoadingSpinner } from "../LoadingSpinner";
import { ExternalLink } from "../helpers/ExternalLink";

export const NodePoolDashboard = ({ config, pool, nodePools }) => {
  const nodes = useMemo(() => {
    if (nodePools && nodePools[pool]) return nodePools[pool];
    else return [];
  }, [pool, nodePools]);

  if (!nodePools) {
    return (
      <Container>
        <Row>
          <Col className="text-center">
            <LoadingSpinner text="Loading node pool data..." big twoColumn />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/` }}>
              Home
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Instance Type</th>
                <th>Zone</th>
                <th>CPU</th>
                <th>Memory</th>
              </tr>
            </thead>
            <tbody>
              {nodes.map((node) => (
                <NodeRow config={config} key={node.metadata.name} node={node} />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

const NodeRow = ({ config, node }) => {
  const getNodeMetrics = () => {
    if (!config.grafanaTemplates?.node?.url) {
      return <></>;
    }
    let label = config.grafanaTemplates.node.label || "(metrics)";
    label = label.replace("{{NODE}}", node.metadata.name);
    const url = config.grafanaTemplates.node.url.replace(
      "{{NODE}}",
      node.metadata.name
    );
    return (
      <ExternalLink href={url} className="ms-3">
        {label}
      </ExternalLink>
    );
  };
  return (
    <tr>
      <td>
        {node.metadata.name} {getNodeMetrics()}
      </td>
      <td>
        {node.metadata.labels &&
        node.metadata.labels["node.kubernetes.io/instance-type"]
          ? node.metadata.labels["node.kubernetes.io/instance-type"]
          : "N/A"}
      </td>
      <td>
        {node.metadata.labels &&
        node.metadata.labels["topology.kubernetes.io/zone"]
          ? node.metadata.labels["topology.kubernetes.io/zone"]
          : "N/A"}
      </td>
      <td>{node.status.allocatable.cpu}</td>
      <td>{node.status.allocatable.memory}</td>
    </tr>
  );
};
