import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { TokenProvider } from "./components/TokenProvider";
import { AppContextProvider } from "./components/AppContextProvider";
import { App } from "./components/App";
import { UserProvider } from "./components/UserProvider";
import './App.scss';
import { createContext, useContext } from "react";
import { ErrorBoundary } from "./components/ErrorBoundary";

function AppBootstrap({ config }) {
  return (
    <ErrorBoundary>
      <AppConfigContext.Provider value={ config }>
        <TokenProvider config={config}>
          <Router>
            <UserProvider authRequired={config.authRequired}>
              <AppContextProvider config={config}>
                <ToastContainer position="bottom-right" />
                <App config={config} />
              </AppContextProvider>
            </UserProvider>
          </Router>
        </TokenProvider>
      </AppConfigContext.Provider>
    </ErrorBoundary>
  );
}

export default AppBootstrap;

const AppConfigContext = createContext(null);

export const useAppConfig = () => {
  return useContext(AppConfigContext);
}