import { useCallback, useContext, useState, useEffect, useMemo, createContext } from "react";

const UserContext = createContext(null);

export const UserProvider = ({ authRequired, children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [user, setUser] = useState(null);
  const [needToLogout, setNeedToLogout] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);

  const logout = useCallback(() => {
    setNeedToLogout(true);
  }, []);

  useEffect(() => {
    if (!needToLogout) return;
    fetch("/auth/logout")
      .then(r => {
        setLoggedOut(true);
      });
  }, [needToLogout]);

  useEffect(() => {
    fetch("/api/whoami")
      .then(r => {
        if (r.status === 404) throw new Error("No endpoint");
        return r.json();
      })
      .then(data => {
        setUser(data.user);
        setAccessToken(data.accessToken);
      })
      .catch(() => {});
  }, []);

  const userDetails = useMemo(() => ({
    user, accessToken, logout, loggedOut
  }), [user, accessToken, logout, loggedOut]);

  if (authRequired && !user)
    return null;

  if (needToLogout) {

  }

  return (
    <UserContext.Provider value={userDetails}>
      { children }
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
}
