import { useCallback, useState } from "react";
import { faCopy, faTerminal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import { useUser } from "../UserProvider";
import { useAppConfig } from "../../AppBootstrap";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from "react-toastify";

export const ConfigureCliButton = (() => {
  const { user, accessToken } = useUser();
  const [showModal, setShowModal] = useState(false);
  const { kubeConfig } = useAppConfig();
  
  const username = user.email.split("@")[0];

  const handleClose = useCallback(() => setShowModal(false), []);
  const handleOpen = useCallback(() => setShowModal(true), []);

  return (
    <>
      <Nav.Link className="pe-3" onClick={handleOpen}>
        <FontAwesomeIcon icon={faTerminal} />
      </Nav.Link>

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Configure your Local CLI/Tools</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>The following commands can be used to configure your local machine to query resources using Kubectl or other tools.</p>

          <h3>One-time setup</h3>
          <p>The following commands will need to be run only once, as the settings stay constant.</p>

          <DisplayAndCopy rows={2} text={`kubectl config set-cluster ${ kubeConfig.clusterName } --server=${ kubeConfig.apiEndpoint }`} />
          <DisplayAndCopy rows={2} text={`kubectl config set-context ${ username }-${ kubeConfig.clusterName } --cluster=${ kubeConfig.clusterName } --user=${ username }-${ kubeConfig.clusterName }`} />
          <DisplayAndCopy rows={1} text={`kubectl config use-context ${ username }-${ kubeConfig.clusterName }`} />

          <h3>More frequent setup</h3>
          <p>Since the credential to access the cluster expires, you will need to frequently refresh it. You can use the following command to do so:</p>
          
          <DisplayAndCopy rows={3} text={`kubectl config set-credentials ${ username }-${ kubeConfig.clusterName } --token="${accessToken}"`} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

function DisplayAndCopy({ text, rows }) {
  const onCopy = useCallback(() => {
    toast.success("Command copied successfully");
  }, []);

  return (
    <InputGroup className="mb-3">
      <FormControl
        as="textarea"
        value={text}
        className="font-monospace"
        rows={rows}
      />
      <CopyToClipboard text={text} onCopy={onCopy}>
        <Button variant="outline-secondary">
          <FontAwesomeIcon icon={faCopy} fixedWidth />
        </Button>
      </CopyToClipboard>
    </InputGroup>
  );
}