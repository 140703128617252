const array_contains = (arr, elements) => {
  for (let i = 0; i < elements.length; i++) {
    if (!arr.includes(elements[0]))
      return false;
  }
  return true;
}

module.exports = {
  array_contains,
};