import Accordion from 'react-bootstrap/Accordion';
import { useMemo } from "react";
import Table from "react-bootstrap/Table";
import { LoadingSpinner } from "../../LoadingSpinner";
import TimeAgo from "react-timeago";
import { InputFilter, TableFilter } from "../Filters";
import { useTableContext, TableContextProvider } from "../../context/TableContext";

export const CertificateCard = ({ eventKey, certificates }) => {
  return (
    <TableContextProvider data={certificates} total={certificates.length}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>Certificates ({certificates.length})</Accordion.Header>
        <Accordion.Body>
          <CertificateFilter />
          {certificates ? <CertificateTable certificates={certificates} /> : <LoadingSpinner text="Loading certificates..." />}
        </Accordion.Body>
      </Accordion.Item>
    </TableContextProvider>
  )
};

export const CertificateTable = (props) => {
  const { total, filteredData, filteredTotal } = useTableContext();

  if (total < 1) {
    return (
      <p>You have no certificates defined yet!</p>
    );
  } else if (filteredTotal < 1) {
    return (
      <p>No pod names match the filter provided</p>
    );
  }

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Name</th>
          <th>Common Name</th>
          <th>Alternate Names</th>
          <th>Status</th>
          <th>Expiration</th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map(certificate => (
          <CertificateRow certificate={certificate} key={certificate.metadata.name} />
        ))}
      </tbody>
      <tfoot>
        <tr><td>{filteredTotal} of {total}</td></tr>
      </tfoot>
    </Table>
  );
}

const CertificateRow = ({ certificate }) => {
  const expirationDate = useMemo(() => {
    if (certificate.status)
      return new Date(certificate.status.notAfter);
    return null;
  }, [certificate]);

  return (
    <tr>
      <td>{ certificate.metadata.name }</td>
      <td>{ certificate.spec.commonName }</td>
      <td>
        { certificate.spec.dnsNames.length === 1 ? (
          certificate.spec.dnsNames[0]
        ) : (
          <ul className="mb-0">
            { certificate.spec.dnsNames.map(name => (
              <li key={name}>{name}</li>
            ))}
          </ul>
        )}
      </td>
      <td>{ certificate.status && certificate.status.conditions[0].reason }</td>
      <td>{ expirationDate && <TimeAgo date={expirationDate} /> }</td>
    </tr>
  );
};

const CertificateFilter = () => {
  const certQuery = (certificate, inputValue) =>
    certificate.metadata.name.includes(inputValue) || certificate.spec.commonName.includes(inputValue);

  return (<TableFilter displayAt={10} >
    <InputFilter name="certificate" placeholder="Filter by certificate name" query={certQuery} />
  </TableFilter>);
}