import { useEffect } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { useAppContext } from "../AppContextProvider";
import { Dashboard } from "./Dashboard/Dashboard";
import { IngressRoute } from "./Ingresses/IngressRoute";
import { IssuesProvider } from "./issues/IssuesProvider";
import { NamespacedK8sDataProvider } from "./NamespacedK8sDataProvider";
import { PodInfoRoute } from "./PodInfo/PodInfoRoute";
import { SealedSecretsRoute } from "./SealedSecrets/SealedSecretsRoute";
import { VulnerabilityReportsRoute } from "./VulnerabilityReports/VulnerabilityReportsRoute";

const NamespaceRoute = ({config}) => {
  const { namespace } = useParams();
  const { setActiveNamespace } = useAppContext();

  useEffect(() => {
    setActiveNamespace(namespace);
    return () => setActiveNamespace(null);
  }, [namespace, setActiveNamespace]);

  return (
    <NamespacedK8sDataProvider namespace={namespace}>
      <IssuesProvider>
        <Routes>
          <Route index exact element={<Dashboard config={config} />} />
          <Route path="sealed-secrets" element={<SealedSecretsRoute />} />
          <Route path="ingresses/:ingressName" element={<IngressRoute />} />
          <Route path="pods/:podIdentifier" element={<PodInfoRoute config={config} />} />
          <Route path="vulnerability-reports/:reportName" element={<VulnerabilityReportsRoute config={config} />} />
        </Routes>
      </IssuesProvider>
    </NamespacedK8sDataProvider>
  );
};

export default NamespaceRoute;