import Accordion from 'react-bootstrap/Accordion';
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../../LoadingSpinner";
import { InputFilter, TableFilter } from "../Filters";
import { useTableContext, TableContextProvider } from "../../context/TableContext";

export const VulnerabilityReportCard = ({ eventKey, reports }) => {

  return reports?.length ? (
    <TableContextProvider data={reports} total={reports?.length}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>Vulnerability Reports</Accordion.Header>
        <Accordion.Body>
          <VulnerabilityFilter />
          {reports ? <ReportTable reports={reports} /> : <LoadingSpinner text="Loading reports..." />}
        </Accordion.Body>
      </Accordion.Item>
    </TableContextProvider>
  ) : null
};

export const ReportTable = (props) => {
  const { total, filteredData, filteredTotal } = useTableContext();

  if (total < 1) {
    return (
      <p>You have no vulnerability reports yet!</p>
    );
  } else if (filteredTotal < 1) {
    return (
      <p>No report names match the filter provided</p>
    );
  }

  return (
    <Container>
      {filteredData.map(report => (
          <ReportRow report={report} key={report.metadata.name} />
        ))}
    </Container>
  );
}

const ReportRow = ({ report }) => {
  return (
    <Row className="my-3 row-eq-height">
      <Col sm={12} md={6} xl={5} className="mb-3">
        {/* TODO issue #2 - create a page with the full report and link to it from here */}
        <Row className="h4"><Link to={`vulnerability-reports/${report.metadata.name}`}>{ report.metadata.name }</Link></Row>
        <Row>{ `${report.metadata.labels["trivy-operator.resource.kind"]}: ${report.metadata.labels["trivy-operator.container.name"]}` }</Row>
        <Row>{report.metadata.labels["trivy-operator.resource.name"]}</Row>
        <Row>{ `${report.report.registry.server}/${report.report.artifact.repository}:${report.report.artifact.tag}` }</Row>
      </Col>
      <Col>
        <Card className="bg-danger text-white vulnerability-card">
          <div className="number">{ report.report.summary.criticalCount }</div>
          <div className="title">Critical Vulnerabilities</div>
        </Card>
      </Col>
      <Col>
        <Card className="bg-warning text-white vulnerability-card">
          <div className="number">{ report.report.summary.highCount }</div>
          <div className="title">High Vulnerabilities</div>
        </Card>
      </Col>
      <Col>
        <Card className="bg-secondary text-white vulnerability-card">
          <div className="number">{ report.report.summary.mediumCount }</div>
          <div className="title">Medium Vulnerabilities</div>
        </Card>
      </Col>
      <Col>
        <Card className="bg-info text-white vulnerability-card">
          <div className="number">{ report.report.summary.lowCount }</div>
          <div className="title">Low Vulnerabilities</div>
        </Card>
      </Col>
    </Row>
  );
};

const VulnerabilityFilter = () => {
  const vulnQuery = (report, inputValue) =>
    report.metadata.name.includes(inputValue) || report.metadata.name.includes(inputValue);

  return (<TableFilter displayAt={10} >
    <InputFilter name="report" placeholder="Filter by report name" query={vulnQuery} />
  </TableFilter>);
}