import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PodLogViewer } from "./PodLogViewer";

export const PodLogLauncher = ({ pod, containerName }) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = useCallback(() => setShowModal(false), []);

  return (
    <>
      <Button size="sm" variant="secondary" onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon={faFileAlt} />&nbsp;
        View Logs
      </Button>

      <Modal show={showModal} onHide={handleClose} size="full">
        <Modal.Header closeButton>
          <Modal.Title>Pod Logs - {pod.metadata.name} - { containerName }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PodLogViewer containerName={containerName} pod={pod} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};