import { useContext, useEffect, useRef } from "react";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import TableContext from "../../context/TableContext";

/**
 * A Form Control input that uses table context to filter the data in the table.
 * @param {object} props 
 * @param {string} props.name - Name to be applied to the Form Control
 * @param {string} props.label - Label text to show above the Form Control
 * @param {function} props.query - A function to execute filter a record based on result of the query
 * @param {number} props.debounce - Time in milliseconds after a keypress in the filter form control before the query is executed
 * @param {object} props.rest - Any other props that the Form Control can take can be provided here
 * @returns 
 */
const InputFilter = (props) => {
  const {
    name,
    label,
    query,
    debounce = 500,
    ...rest
  } = props;
  const ref = useRef('');
  const { data, setFilteredData, setFilter } = useContext(TableContext);

  let debounceTimeout = null;

  useEffect(() => {
    setFilter(prev => ({
      name,
      ref,
      query,
      value: ref.current.value
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, query]);

  const updateFilteredData = () => {
    setFilteredData(() => {
      // If there is not value in the filter then return the full dataset.
      return ref.current.value
        ? data.filter(entry => query(entry, ref.current.value))
        : data;
    });
  }

  // If the dataset changes then update the filtered data.
  useEffect(() => {
    updateFilteredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      updateFilteredData();
    }, debounce);
  }

  const handleClick = () => {
    ref.current.value = "";
    setFilteredData(data);
  }

  return (
    <InputGroup>
      <FormControl
        {...rest}
        aria-label={rest.placeholder}
        name={name}
        ref={ref}
        type="text"
        onChange={handleChange}
      />
      <Button
        variant="light-outline"
        type="button"
        onClick={handleClick}
        className="close-btn">&#x2715;</Button>
    </InputGroup>)
};

export default InputFilter;