import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Route, Routes } from "react-router-dom";
import { useAppContext } from "./AppContextProvider";
import { LoggedOutPage } from "./LoggedOutPage";
import { NotFound } from "./NotFound";
import { useUser } from "./UserProvider";
import { WelcomePage } from "./WelcomePage";
import { Layout } from "./Layout";
import NamespaceRoute from "./Namespaces/NamespaceRoute";
import NodePoolRoute from "./NodePools/NodePoolRoute";
import { AdminRoute } from "./Admin/AdminRoute";

export const App = ({ config }) => {
  const { namespaces, nodePools, hasFetchError, setActiveNamespace } =
    useAppContext();
  const { loggedOut } = useUser();

  if (!namespaces) {
    return hasFetchError ? (
      <p>Authentication required before using this app</p>
    ) : (
      <StartingUp />
    );
  }

  if (!nodePools) {
    return hasFetchError ? (
      <p>Authentication required before using this app</p>
    ) : (
      <StartingUp />
    );
  }

  if (loggedOut) {
    return <LoggedOutPage />;
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <WelcomePage
              namespaces={namespaces}
              setActiveNamespace={setActiveNamespace}
              nodePools={nodePools}
            />
          }
        />
        <Route
          path="/namespaces/:namespace/*"
          element={<NamespaceRoute config={config} />}
        />
        <Route
          path="/node-pools/:nodePool/*"
          element={<NodePoolRoute config={config} />}
        />
        <Route path="/admin/*" element={<AdminRoute />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

const StartingUp = () => {
  return (
    <div className="text-center text-muted mt-5">
      <FontAwesomeIcon icon={faSpinner} spin size="4x" className="mb-2" />
      <p>Starting up...</p>
    </div>
  );
};
