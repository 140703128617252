import { useMemo } from "react";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import { CertificateTable } from "../Dashboard/CertificateCard";
import { IssuesDisplay } from "../issues/IssuesDisplay";
import { LoadingSpinner } from "../../LoadingSpinner";
import { array_contains } from "../../../utils/arrays";
import k8s from "../../../utils/k8s";
import { TableContextProvider } from "../../context/TableContext";

export const CertificateIngressCard = ({ ingress, certificates }) => {

  const matchingCerts = useMemo(() => {
    if (!certificates || !ingress) return;
    return k8s.findCertsForIngress(certificates, ingress);
  }, [certificates, ingress]);

  const secretIssues = useMemo(() => {
    const issues = [];
    ingress.spec.tls.forEach((tlsRule) => {
      if (matchingCerts.length === 0) {
        return issues.push({
          type: "error",
          description: "No Certificate was found for this Ingress",
        });
      }

      const matchingCert = matchingCerts
        .filter(c => array_contains(c.spec.dnsNames, tlsRule.hosts))
        .filter(c => c.spec.secretName === tlsRule.secretName);

      if (matchingCert.length === 0)
        issues.push({
          type: "error",
          description: `There appears to be a mismatch between the secretName defined on the Certificate and the TLS config on the Ingress`
        });
    })
    return issues;
  }, [matchingCerts, ingress]);

  return (
    <TableContextProvider
      data={matchingCerts}
      total={matchingCerts.length}
      filteredData={matchingCerts}
      filteredTotal={matchingCerts.length}
    >
      <Card className="mb-3">
        <Card.Header className="bg-secondary text-white">Certificates</Card.Header>
        <Card.Body>
          <p>All domains are required to use TLS in order to receive HTTP traffic. While TLS termination occurs for you, there are still a few configuration pieces you have to define.</p>

          {matchingCerts ? (
            <CertificateTable  />
          ) : (
            <LoadingSpinner text="Loading data..." />
          )}

          {secretIssues && secretIssues.length > 0 && (
            <Alert variant="danger">
              <IssuesDisplay issues={secretIssues} />
            </Alert>
          )}
        </Card.Body>
      </Card>
    </TableContextProvider>
  )
};