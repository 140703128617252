const { array_contains } = require("../utils/arrays");

export const findIssues = ({ pods, kustomizations, ingresses, services, certificates }) => {
  const issues = [];

  if (!pods || !kustomizations || !ingresses || !services || !certificates)
    return issues;

  console.time("issueFinding");

  if (kustomizations.length === 1 && (kustomizations[0].status.conditions[0].reason === "ReconciliationFailed" || kustomizations[0].status.conditions[0].reason === "BuildFailed")) {
    issues.push({
      type: "error",
      description: "Unable to apply manifests: " + kustomizations[0].status.conditions[0].message,
      references: [ kustomizations[0] ]
    });
  }

  ingresses.forEach(ingress => {
    if (ingress.apiVersion !== "networking.k8s.io/v1") {
      issues.push({
        type: "warning",
        description: `Ingress ${ingress.metadata.name} needs to be updated to the networking.k8s.io apiVersion`,
        references: [ ingress ],
        helpLink: "https://kubernetes.io/docs/concepts/services-networking/ingress/"
      })
    }
    else {
      ingress.spec.rules.forEach(rule => {
        rule.http.paths.forEach(path => {
          const ingressService = path.backend.service;
          const service = services.find(s => s.metadata.name === path.backend.service.name);
          if (service === undefined) {
            issues.push({
              type: "error",
              description: `Ingress ${ingress.metadata.name} references a Service named ${path.backend.service.name}, but no matching Service is found`,
              references: [ ingress ],
            });
          }
          else {
            if (ingressService.port.name) {
              const port = (service.spec.ports.find(p => p.name === ingressService.port.name));
              if (port === undefined) {
                issues.push({
                  type: "error",
                  description: `Ingress ${ingress.metadata.name} references a port named ${ingressService.port.name} on Service ${ingressService.name}, but that port isn't found on the Service`,
                  references: [ ingress, service ]
                });
              }
            }
            if (ingressService.port.number) {
              const port = (service.spec.ports.find(p => p.port === ingressService.port.number));
              if (port === undefined) {
                issues.push({
                  type: "error",
                  description: `Ingress ${ingress.metadata.name} references port ${ingressService.port.number} on Service ${ingressService.name}, but that port isn't found on the Service`,
                  references: [ ingress, service ]
                });
              }
            }
          }
        })
      })
    }
  });

  // Ingress/Certificate issues
  ingresses.forEach((ingress) => {
    if (!ingress.spec.tls) {
      return issues.push({
        type: "error",
        description: `Ingress ${ingress.metadata.name} doesn't specify any TLS configuration`,
        references: [ ingress ],
        helpLink: "https://kubernetes.io/docs/concepts/services-networking/ingress/#tls"
      });
    }

    else {
      ingress.spec.tls.forEach((tlsRule) => {
        const certs = certificates.filter(cert => array_contains(cert.spec.dnsNames, tlsRule.hosts));
        if (certs.length === 0) {
          issues.push({
            type: "error",
            description: `Ingress ${ingress.metadata.name} doesn't have a Certificate that matches all defined TLS hosts`,
            references: [ ingress ],
          })
        }
        else {
          if (certs.find(c => c.spec.secretName === tlsRule.secretName) === undefined) {
            issues.push({
              type: "error",
              description: `Ingress ${ingress.metadata.name} indicates the TLS cert should be stored at ${tlsRule.secretName}, but all Certificates are using a different secret name`,
              references: [ ingress, ...certs ],
            });
          }
        }
      });
    }
  });

  services.forEach((service) => {
    if (!service.spec.selector) return;

    const requiredLabels = Object.keys(service.spec.selector);

    const matchingPod = pods
      .filter(pod => pod.metadata.labels !== undefined)
      .find((pod) => {
        for (var i = 0; i < requiredLabels.length; i++) {
          if (pod.metadata.labels[requiredLabels[i]] !== service.spec.selector[requiredLabels[i]])
            return false;
        }
        return true;
      });

    if (!matchingPod) {
      issues.push({
        type: "error",
        description: `Service ${service.metadata.name} has a selector that does not currently match any running pods. Validate the pods have labels that match the Service selector.`,
        references: [ service ],
        helpLink: ""
      })
    }
  });

  certificates.forEach((certificate) => {
    if (certificate.spec.dnsNames.indexOf(certificate.spec.commonName) === -1) {
      issues.push({
        type: "error",
        description: `The Certificate ${certificate.metadata.name} contains a commonName (${certificate.spec.commonName}) that isn't listed in the dnsNames`,
        references: certificate,
      });
    }
  });

  pods
    .filter(pod => pod.metadata.ownerReferences && pod.metadata.ownerReferences[0].kind === "ReplicaSet")
    .filter(pod => !pod.metadata.deletionTimestamp)
    .forEach(pod => {
      pod.spec.containers.forEach(container => {
        if (!container.resources || !container.resources.limits || !container.resources.limits.memory) {
          issues.push({
            type: "warning",
            description: `Container ${container.name} on pod ${pod.metadata.name} does not specify memory limits`,
            references: [ pod ]
          });
        }

        if (!container.resources || !container.resources.requests || !container.resources.requests.cpu) {
          issues.push({
            type: "warning",
            description: `Container ${container.name} on pod ${pod.metadata.name} does not specify CPU requests`,
            references: [ pod ]
          });
        }

        if (!container.resources || !container.resources.requests || !container.resources.requests.memory) {
          issues.push({
            type: "warning",
            description: `Container ${container.name} on pod ${pod.metadata.name} does not specify memory requests`,
            references: [ pod ]
          });
        }

        if (!container.readinessProbe) {
          issues.push({
            type: "info",
            description: `Container ${container.name} on pod ${pod.metadata.name} does not specify a readiness probe`,
            references: [ pod ],
            helpLink: "https://kubernetes.io/docs/tasks/configure-pod-container/configure-liveness-readiness-startup-probes/#define-readiness-probes",
          });
        }

        if (!container.livenessProbe) {
          issues.push({
            type: "warning",
            description: `Container ${container.name} on pod ${pod.metadata.name} does not specify a liveness probe`,
            references: [ pod ],
            helpLink: "https://kubernetes.io/docs/tasks/configure-pod-container/configure-liveness-readiness-startup-probes/"
          });
        }

      });
     });

  console.timeEnd("issueFinding");  

  return issues;
}
