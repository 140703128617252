import { useMemo } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "./PodDisplay.scss";

export const PodDisplay = ({ pod }) => {
  const status = useMemo(() => {
    if (!pod) return;

    let ready = 0;
    let running = 0;
    // let restarts = 0;

    if (pod.status.phase === "Succeeded") return "succeeded";

    if (pod.status.containerStatuses) {
      for (const container of pod.status.containerStatuses) {
        if (container.ready) {
          ready++;
        }
        if (container.state && container.state.running) {
          running++;
        }
        // restarts += container.restartCount || 0
      }
    }

    const allReady = ready >= pod.spec.containers.length;
    const allRunning = running >= pod.spec.containers.length;

    if (pod.status.phase === "Running" && allReady) return "running";
    if (pod.status.phase === "Running" && allRunning && !allReady)
      return "starting";
    if (pod.status.phase === "Pending") return "pending";

    // CrashLoopBackOff, ImagePullBackOff or other unknown state
    return "crashed";
  }, [pod]);

  const popover = (
    <Popover id={`popover-${pod.metadata.name}`}>
      <Popover.Body>{pod.metadata.name}</Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger placement="auto" overlay={popover}>
      <div className={`pod-display pod ${status} font-monospace`}>
        <p>&nbsp;</p>
      </div>
    </OverlayTrigger>
  );
};
