import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingSpinner } from "../LoadingSpinner";

export const PodStatus = ({ pod }) => {
  if (pod.metadata.deletionTimestamp)
    return <LoadingSpinner text="Terminating..." />;

  if (pod.status.phase === "Running" || pod.status.phase === "Succeeded")
    return pod.status.phase;
  
  if (pod.status.containerStatuses) {
    const pullErrors = pod.status.containerStatuses.find(s => s.state.waiting && (s.state.waiting.reason === "ErrImagePull" || s.state.waiting.reason === "ImagePullBackOff"));
    if (pullErrors !== undefined) {
      return (
        <>
          <OverlayTrigger
            placement="top"
            key={`${pod.metadata.name}-error`}
            overlay={
              <Tooltip id={`${pod.metadata.name}-error`}>
                { pullErrors.state.waiting.message }
              </Tooltip>
            }
          >
            <Button variant="link" size="xs">
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />
            </Button>
          </OverlayTrigger>&nbsp;
          <span>Error pulling image</span>
        </>
      );
    }
  }

  return pod.status.phase;
};