import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const LoadingSpinner = ({ text, big, twoColumn }) => {
  if (twoColumn) return (
    <>
      <div className="mb-2"><FontAwesomeIcon icon={faSpinner} spin size={big ? "4x" : undefined} /></div>
      { text }
    </>
  );

  return (
    <>
      <FontAwesomeIcon icon={faSpinner} spin size={big ? "4x" : undefined} />
      &nbsp;{ text }
    </>
  )
};