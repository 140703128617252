import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/esm/Container";

export const LoggedOutPage = () => {

  const loginAgain = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Container className="mt-5">
      <Row>
        <Col className="text-center">
          <FontAwesomeIcon icon={faCheckCircle} className="text-success" size="6x" />
          <p>You have successfully logged out of the dashboard!</p>
          <p>Note that you have not been logged out of your VT Login.</p>
          <p><Button variant="primary" onClick={loginAgain}>Log in again</Button></p>
        </Col>
      </Row>
    </Container>
  )
};
