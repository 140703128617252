import {useMemo} from "react";
import TimeAgo from "react-timeago";

export const AgeDisplay = ({ date }) => {
  const dateObj = useMemo(() => {
    return new Date(date);
  }, [date]);

  return (
    <TimeAgo date={dateObj} />
  );
}