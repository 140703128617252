import { faClock, faMapMarkerAlt, faServer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";
import { PodDisplay } from "./PodDisplay";

const NodeInfoCard = ({ node }) => {
  return (
    <Card className="mb-4" style={{height: "100%"}}>
      <Card.Header className={`${node.metadata.labels["karpenter.sh/capacity-type"] === "spot" ? "bg-warning" : "bg-secondary"} text-white text-truncate`}><small>{ node.id }</small></Card.Header>
      <Card.Body>
        <div className="d-flex flex-column" style={{height:"100%"}}>
          <div className="node-metadata mb-2">
            <FontAwesomeIcon icon={faServer} />&nbsp;&nbsp;{ node.metadata.labels["beta.kubernetes.io/instance-type"] || <em>Unknown</em>}
            &nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;&nbsp;{ node.metadata.labels["topology.kubernetes.io/zone"] || <em>Unknown</em>}
            { node.metadata.labels["karpenter.sh/capacity-type"] === "spot" && (
              <>
                &nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon icon={faClock} />&nbsp;&nbsp;Spot}
              </>
            )}
          </div>
          <div className="pods flex-grow-1">
            { node.pods.map(pod => (
              <PodDisplay pod={pod} key={pod.metadata.name} />
            ))}
          </div>

          <div className="daemon-sets flex-shrink-1 justify-content-end d-flex flex-wrap-reverse">
            { node.daemonSets.map(pod => (
              <PodDisplay pod={pod} key={pod.metadata.name} />
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
};

export default NodeInfoCard;