import { createContext, useState, useEffect, useContext } from "react";

const TableContext = createContext({
  data: [],
  filter: {},
  setFilter: null,
  filteredData: [],
  total: null,
  filteredTotal: null
});

export const TableContextProvider = (props) => {
  const [data, setData] = useState(props.data || []);
  const [filteredData, setFilteredData] = useState(props.filteredData || []);
  const [filter, setFilter] = useState(props.filter);
  const [total, setTotal] = useState(props.data.length);
  const [filteredTotal, setFilterTotal] = useState(0);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setTotal(props.data.length);
    }
  }, [props.data]);

  useEffect(() => {
    if (filteredData) {
      setFilterTotal(filteredData.length);
    }
  }, [filteredData]);

  return (<TableContext.Provider value={{
    data,
    total,
    filteredData,
    filteredTotal,
    setFilterTotal,
    setFilteredData,
    filter,
    setFilter
  }}>
    {props.children}
  </TableContext.Provider>)
}

export const useTableContext = () => {
  return useContext(TableContext);
}

export default TableContext;

