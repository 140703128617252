import * as React from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

export const TokenProvider = ({ config, children }) => {
  const [token, setToken] = React.useState(null);

  if (config.authRequired &&!config.redirectUri && !token)
    return <TokenForm onSet={setToken} tokenEndpoint={config.tokenAppUrl} />;

  return (
    <TokenContext.Provider value={token}>
      { children }
    </TokenContext.Provider>
  );
}

const TokenForm = ({ onSet, tokenEndpoint }) => {
  const [token, setToken] = React.useState("");
  const [error, setError] = React.useState(null);

  const validateAndSubmit = React.useCallback(() => {
    fetch("/api/verify-auth", {
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    }).then(r => {
      if (r.status === 200) onSet(token);
      else setError(true);
    });
  }, [token, onSet]);

  return (
    <Modal show={true} backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Auth Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { error && (
          <Alert variant="warning">
            An error occurred trying to validate the token. Make sure you copy/pasted the entire token.
          </Alert>
        )}
        <p>
          In order to use the dashboard, you need to authenticate. While we'll eventually make this a little easier, we'll iterate towards that. Until then, follow the steps below. This auth token is stored only in your browser's memory.
        </p>
        <ol>
          <li>Go to <a href={ tokenEndpoint }>{ tokenEndpoint }</a> and login using VT Gateway</li>
          <li>Go to the <strong>ID Token</strong> tab</li>
          <li>Copy the token</li>
          <li>Paste it in the box below:</li>
        </ol>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Token</Form.Label>
            <Form.Control as="textarea" rows={3} value={token} onChange={(e) => setToken(e.target.value)}></Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer> 
        <Button variant="primary" disabled={!token} onClick={validateAndSubmit}>
          Use Token
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

const TokenContext = React.createContext(null);

export const useAuthToken = () => {
  return React.useContext(TokenContext);
};
