import { useMemo } from "react";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";

export const MemoryUsageDisplay = ({ request, limit, usage }) => {
  function toBytes(val) {
    if (val.endsWith("Ti")) {
      return parseInt(val.replace("Ti")) * 1024 * 1024 * 1024 * 1024;
    } else if (val.endsWith("Gi")) {
      return parseInt(val.replace("Gi")) * 1024 * 1024 * 1024;
    } else if (val.endsWith("Mi")) {
      return parseInt(val.replace("Mi")) * 1024 * 1024;
    } else if (val.endsWith("Ki")) {
      return parseInt(val.replace("Ki")) * 1024;
    } else if (val.endsWith("K")) {
      return parseInt(val.replace("K")) * 1000;
    } else if (val.endsWith("M")) {
      return parseInt(val.replace("M")) * 1000000;
    } else if (val.endsWith("G")) {
      return parseInt(val.replace("G")) * 1000000000;
    } else if (val.endsWith("T")) {
      return parseInt(val.replace("T")) * 1000000000000;
    } else {
      return val;
    }
  }
  function humanReadable(val) {
    var bytes = toBytes(val);
    var i = -1;
    var byteUnits = ['kb', 'MB', 'GB', 'TB'];
    do {
      bytes /= 1024;
      i++;
    } while(bytes > 1024);
    return bytes.toFixed(2) + " " + byteUnits[i];
  }

  const percentage = useMemo(() => {
    if (! limit || !usage) return 0
    return toBytes(usage) / toBytes(limit) * 100;
  }, [limit, usage]);

  const variant = useMemo(() => {
    if (percentage < 70)
      return "success";
    return percentage < 85 ? "warning" : "danger";
  }, [percentage]);

  if (!limit)
    return humanReadable(usage);

  return (                                    
    <Row>
      <Col>Requested { humanReadable(request) }. Using { humanReadable(usage) } of the limited { humanReadable(limit) } memory</Col>
      <Col><ProgressBar now={percentage} variant={variant} /></Col>
    </Row>
  );
};