import { useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NodePoolCard from "./NodePoolCard";
import { useAdminData } from "../AdminDataProvider";
import { LoadingSpinner } from "../../LoadingSpinner";

export const NodePoolRoute = () => {
  const { nodes, pods } = useAdminData();
  const [showDaemonSets, setShowDaemonSets] = useState(true);

  const nodePools = useMemo(() => {
    if (!nodes || !pods) return [];

    const pools = {};
    
    nodes.forEach((node) => {
      const poolName = node.metadata.labels["platform.it.vt.edu/node-pool"] ?
        node.metadata.labels["platform.it.vt.edu/node-pool"] : "unknown";

      if (!pools[poolName])
        pools[poolName] = { 
          costCode: node.metadata.labels["platform.it.vt.edu/cost-code"],
          nodes: [],
        };

      const allPods = pods.filter(p => p.spec.nodeName === node.metadata.name);
      pools[poolName].nodes.push({
        id: node.metadata.name,
        pods: allPods.filter(p => !p.metadata.ownerReferences || p.metadata.ownerReferences.find(ref => ref.kind === "DaemonSet") === undefined),
        daemonSets: showDaemonSets ? allPods.filter(p => p.metadata.ownerReferences && p.metadata.ownerReferences.find(ref => ref.kind === "DaemonSet") !== undefined) : [],
        ...node,
      });
    });

    return Object.keys(pools).map((poolId) => ({
      id: poolId,
      ...pools[poolId],
    }));
  }, [nodes, pods, showDaemonSets]);

  return (
    <Container>
      <Row>
        <Col>
          <h1>Node Pool View</h1>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Show DaemonSets" checked={showDaemonSets} onChange={() => setShowDaemonSets(!showDaemonSets)} />
          </Form.Group>
        </Col>
      </Row>

      { nodePools.length === 0 && (
        <LoadingSpinner text={"Loading node pool data..."} />
      )}

      { nodePools.map((nodePool) => (
        <Row key={nodePool.id}>
          <Col>
            <NodePoolCard nodePool={nodePool} />
          </Col>
        </Row>
      ))}
    </Container>
  );
};
