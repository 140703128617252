import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom"
import { useParams } from "react-router"
import { useEffect, useState } from "react";
import { LoadingSpinner } from "../../LoadingSpinner";
import { SealedSecretPage } from "./SealedSecretPage";


export const SealedSecretsRoute = () => {
  const { namespace } = useParams();
  const [certificate, setCertificate] = useState(null);
  const [certError, setCertError] = useState(null);

  useEffect(() => {
    fetch("/api/sealed-secrets-cert.pem")
      .then(r => {
        if (r.status !== 200)
          return setCertError(r.status);
        return r.text();
      })
      .then(setCertificate);
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/` }}>Home</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${namespace}` }}>{namespace}</Breadcrumb.Item>
            <Breadcrumb.Item active>Sealed Secrets Tool</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Sealed Secrets Tool</h2>

          { certError ? (
            <Alert variant="danger">
              The Sealed Secrets Tool is current unavailable as the encryption key cannot be loaded into the browser.
            </Alert>
          ) : (
            <>
              { certificate ? (
                <SealedSecretPage namespace={namespace} certificate={certificate} />
              ): (
                <LoadingSpinner text="Loading encryption key..." />
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};