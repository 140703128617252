import Accordion from 'react-bootstrap/Accordion';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  useNamespaceData,
  usePodMetrics,
  useVulnerabilityReports,
} from "../NamespacedK8sDataProvider";
import { PodCard } from "./PodCard";
import { CertificateCard } from "./CertificateCard";
import { DomainsCard } from "./DomainsCard";
import { KustomizationCard } from "./KustomizationCard";
import { IssueOverviewCards } from "./IssueOverviewCards";
import { LoadingSpinner } from "../../LoadingSpinner";
import { ExternalLink } from "../../helpers/ExternalLink";
import { VulnerabilityReportCard } from "./VulnerabilityReportCard";

export const Dashboard = ({ config }) => {
  const {
    namespace,
    certificates,
    ingresses,
    services,
    pods,
    kustomizations,
    endpoints,
  } = useNamespaceData();
  const { metrics } = usePodMetrics();
  const { vulnerabilityReports } = useVulnerabilityReports();

  const getNamespaceMetrics = () => {
    if (!config.grafanaTemplates?.pod?.url) {
      return <></>;
    }
    let label = config.grafanaTemplates.namespace.label || "Metrics";
    label = label.replace("{{NAMESPACE}}", "foo");
    const url = config.grafanaTemplates.namespace.url.replace(
      "{{NAMESPACE}}",
      namespace
    );

    return (
      <Row className="mb-3">
        <Col>
          Namespace Metrics:{" "}
          <ExternalLink href={url} className="ms-3">{label}</ExternalLink>
        </Col>
      </Row>
    );
  };

  if (!pods) {
    return (
      <Container>
        <Row>
          <Col className="text-center">
            <LoadingSpinner text="Loading tenant data..." big twoColumn />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="mb-3 row-eq-height">
        <Col sm={12} md={6} xl={5} className="mb-3">
          <KustomizationCard
            config={config}
            namespace={namespace}
            kustomizations={kustomizations}
          />
        </Col>
        <Col sm={12} md={6} xl={{ offset: 1, span: 6 }}>
          <IssueOverviewCards />
        </Col>
      </Row>
      {getNamespaceMetrics()}
      <Row className="mb-3">
        <Col>
          <Accordion defaultActiveKey="1">
            <DomainsCard
              eventKey="0"
              ingresses={ingresses}
              services={services}
              pods={pods}
              endpoints={endpoints}
            />
            <PodCard eventKey="1" pods={pods} podMetrics={metrics} />
            <CertificateCard eventKey="2" certificates={certificates} />
            <VulnerabilityReportCard eventKey="3" reports={vulnerabilityReports} />
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};
