import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Table from "react-bootstrap/Table";
import Tooltip from "react-bootstrap/Tooltip";
import { useNamespaceData } from "../NamespacedK8sDataProvider";
import { AgeDisplay } from "../../helpers/AgeDisplay";
import { LoadingSpinner } from "../../LoadingSpinner";
import { IssuesDisplay } from "../issues/IssuesDisplay";
import { ExternalLink } from "../../helpers/ExternalLink";
import { Link } from "react-router-dom";

export const PodDetailCard = ({ config, pod, issues }) => {
  const { getServiceAccounts } = useNamespaceData();
  const splunkIndex = useMemo(() => {
    if (!pod.metadata.annotations) return "None configured";
    return pod.metadata.annotations["platform-logging-splunkindex"];
  }, [pod]);

  const [serviceAccounts, setServiceAccounts] = useState(null);

  const awsIamRole = useMemo(() => {
    if (!serviceAccounts) return null;

    const podServiceAccount = pod.spec.serviceAccountName;
    const serviceAccount = serviceAccounts.find(
      (sa) => sa.metadata.name === podServiceAccount
    );
    if (!serviceAccount) return null;
    return serviceAccount.metadata.annotations
      ? serviceAccount.metadata.annotations["eks.amazonaws.com/role-arn"]
      : null;
  }, [pod, serviceAccounts]);

  useEffect(() => {
    getServiceAccounts(setServiceAccounts);
  }, [getServiceAccounts]);

  const sortedVolumes = useMemo(() => {
    return [...pod.spec.volumes].sort((a, b) => (a.name < b.name ? -1 : 1));
  }, [pod]);

  // If we have configuration for links to pod metrics, generate a link
  const getPodMetricsLink = () => {
    if (!config.grafanaTemplates?.pod?.url) {
      return <></>;
    }
    let label = config.grafanaTemplates.pod.label || "Metrics";
    label = label
      .replace("{{NAMESPACE}}", pod.metadata.namespace)
      .replace("{{POD}}", pod.metadata.name);
    const url = config.grafanaTemplates.pod.url
      .replace("{{NAMESPACE}}", pod.metadata.namespace)
      .replace("{{POD}}", pod.metadata.name);
    return (
      <tr>
        <th>Metrics</th>
        <td>
        <ExternalLink href={url}>{label}</ExternalLink>
        </td>
      </tr>
    );
  };

  return (
    <Card className="mb-4">
      <Card.Header className="bg-secondary text-white">Pod Details</Card.Header>
      <Card.Body>
        <Table striped responsive>
          <tbody>
            <tr>
              <th>Creation Date</th>
              <td>
                {pod.metadata.creationTimestamp}&nbsp; (
                <AgeDisplay date={pod.metadata.creationTimestamp} />)
              </td>
            </tr>
            <tr>
              <th>Labels</th>
              <td>
                {Object.entries(pod.metadata.labels).map(([key, value]) => (
                  <div key={key}>
                    <strong>{key}</strong>: {value}
                  </div>
                ))}
              </td>
            </tr>
            <tr>
              <th>Splunk Index</th>
              <td>{splunkIndex ? splunkIndex : <em>None configured</em>}</td>
            </tr>
            {getPodMetricsLink()}
            <tr>
              <th>AWS IAM Role</th>
              <td>
                {serviceAccounts ? (
                  <>{awsIamRole ? awsIamRole : <em>None configured</em>}</>
                ) : (
                  <LoadingSpinner text="Loading..." />
                )}
              </td>
            </tr>
            <tr>
              <th>Pod IP Address</th>
              <td>
                {pod.status.podIP}&nbsp;
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="pod-ip-notice">
                      This IP is internal to the cluster and not directly
                      accessible outside of the cluster
                    </Tooltip>
                  }
                >
                  <Button variant="light" size="xs">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
            <tr>
              <th>Node</th>
              <td>
                {pod.metadata.labels && pod.metadata.labels["node-pool"] ? (
                  <Link to={`/node-pools/${pod.metadata.labels["node-pool"]}`}>
                    {pod.spec.nodeName}
                  </Link>
                ) : (
                  pod.spec.nodeName
                )}
              </td>
            </tr>
            <tr>
              <th>Volumes</th>
              <td>
                {sortedVolumes.map((volume) => (
                  <div key={volume.name}>
                    <VolumeRow volume={volume} />
                  </div>
                ))}
              </td>
            </tr>
            <tr>
              <th>Issues</th>
              <td>
                <IssuesDisplay issues={issues} />
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const VolumeRow = ({ volume }) => {
  const populatedBy = useMemo(() => {
    if (volume.secret)
      return (
        <>
          - populated by the secret named{" "}
          <strong>{volume.secret.secretName}</strong>
        </>
      );
    if (volume.configMap)
      return (
        <>
          - populated by the ConfigMap named{" "}
          <strong>{volume.configMap.name}</strong>
        </>
      );
    if (volume.emptyDir) return <>- simply an empty directory</>;
    if (volume.persistentVolumeClaim)
      return (
        <>
          - connected to the PersistentVolumeClaim named{" "}
          <strong>{volume.persistentVolumeClaim.claimName}</strong>
        </>
      );
    if (volume.name === "aws-iam-token")
      return (
        <>
          - platform-provided volume that contains the AWS credentials needed to
          perform role assumption
        </>
      );
    if (volume.name.startsWith("kube-api-access"))
      return <>- automatically provided by Kubernetes for pod identity</>;
    if (volume.ephemeral) {
      const type =
        volume.ephemeral.volumeClaimTemplate.spec.storageClassName.startsWith(
          "ebs"
        )
          ? "EBS"
          : "EFS";
      const capacity =
        volume.ephemeral.volumeClaimTemplate.spec.resources.requests.storage;
      return (
        <>
          - connected to an ephemeral {type} volume with total capacity of{" "}
          {capacity}
        </>
      );
    }
    return null;
  }, [volume]);

  return (
    <>
      <strong>{volume.name}</strong>&nbsp;{populatedBy}
    </>
  );
};
