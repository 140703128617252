import { useMemo } from "react";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";

export const CpuUsageDisplay = ({ request, limit, usage }) => {
  const humanReadableUsage = useMemo(() => {
    const nanos = parseInt(usage.replace("n", ""));
    return nanos / 1E9;
  }, [usage]);

  const humanReadableLimit = useMemo(() => {
    if (!limit) return null;
    if (!limit.endsWith("m"))
      return limit;

    const millis = parseInt(limit.replace("m", ""));
    return millis / 1000;
  }, [limit]);

  const humanReadableRequest = useMemo(() => {
    if (!request) return 0;
    if (!request.endsWith("m"))
      return parseFloat(request);

    const millis = parseInt(request.replace("m", ""));
    return millis / 1000;
  },[request]);

  const percentage = useMemo(() => {
    return humanReadableUsage / humanReadableLimit * 100;
  }, [humanReadableLimit, humanReadableUsage]);

  const variant = useMemo(() => {
    if (percentage < 70)
      return "success";
    return percentage < 85 ? "warning" : "danger";
  }, [percentage]);

  if (!limit)
    return (humanReadableUsage / 100).toFixed(2) + "%";

  return (
    <Row>
      <Col>Requested { humanReadableRequest.toFixed(2) }. Using { humanReadableUsage.toFixed(2) } of the limited { humanReadableLimit } CPUs</Col>
      <Col><ProgressBar now={percentage} variant={variant} /></Col>
    </Row>
  );
};