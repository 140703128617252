import Accordion from 'react-bootstrap/Accordion';
import { useMemo } from "react";
import Table from "react-bootstrap/Table";
import { PodStatus } from "../../helpers/PodStatus";
import { LoadingSpinner } from "../../LoadingSpinner";
import { Link } from "react-router-dom";
import { AgeDisplay } from "../../helpers/AgeDisplay";
import { InputFilter, TableFilter } from "../Filters";
import { useTableContext, TableContextProvider } from "../../context/TableContext";

export const PodCard = ({ eventKey, pods, podMetrics }) => {
  return (
    <TableContextProvider data={pods} total={pods.length}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>Pods ({pods.length})</Accordion.Header>
        <Accordion.Body>
          < PodFilter />
          {(pods && podMetrics) ?
            <PodTable podMetrics={podMetrics} />
            : <LoadingSpinner text="Loading pods..." />
          }
        </Accordion.Body>
      </Accordion.Item>
    </TableContextProvider>
  )
};

const PodTable = () => {
  const { data, filteredData, filteredTotal, total } = useTableContext();

  const orderedPods = useMemo(() => {
    if (!filteredData) return null;
    return filteredData.sort((a, b) => {
      return a.metadata.name < b.metadata.name ? -1 : 1;
    });
  }, [filteredData]);

  if (data < 1) {
    return (
      <p>You have no pods defined yet!</p>
    );
  } else if (filteredTotal < 1) {
    return (
      <p>No pod names match the filter provided</p>
    );
  }

  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Image</th>
            <th>Status</th>
            <th>Age</th>
          </tr>
        </thead>
        <tbody>
          {orderedPods.map(pod => <PodRow key={pod.metadata.name} pod={pod} />)}
        </tbody>
        <tfoot>
          <tr><td>{filteredTotal} of {total}</td></tr>
        </tfoot>
      </Table>
    </>
  );
}

const PodRow = ({ pod }) => {
  return (
    <tr>
      <td>
        <Link to={`pods/${pod.metadata.name}`}>
          { pod.metadata.name }
        </Link>
      </td>
      <td>{ pod.spec.containers.map(c => c.image).sort().join(", ") }</td>
      <td><PodStatus pod={pod} /></td>
      <td><AgeDisplay date={pod.metadata.creationTimestamp} /></td>
    </tr>
  );
}

const PodFilter = () => {
  const podQuery = (pod, inputValue) => pod.metadata.name.includes(inputValue)

  return (<TableFilter displayAt={10}>
    <InputFilter name="pod-name" placeholder="Filter by pod name" query={podQuery} />
  </TableFilter>);
}